import { Add as AddIcon } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Box, Button, Container, Typography } from "@material-ui/core";
import { useQuery } from "react-query";
import { Link as RouterLink, useLocation } from "react-router-dom";
import AppCircularProgress from "../../components/AppCircularProgress";
import Breadcrumbs from "../../components/Breadcrumbs";
import GuardedComponent from "../../components/GuardedComponent";
import NewsToolbar from "../../components/news/NewsToolbar";
import { getNewsAll } from "../../http/news";
import NewsList from "../../components/news/NewsList";
import FilterPanel from "../../components/FilterPanel";
import { useTrackingContext } from "../../contexts/TrackingContext";

const News = () => {
  const location = useLocation();
  const fromAgent = location.pathname === "/news";
  const itemsPerPage = 9;
  const [page, setPage] = useState(1);
  const [news, setNews] = useState([]);
  const [filters, setFilters] = useState([]);
  const [hasMoreNews, setHasMoreNews] = useState(true);
  const { isFetching, data } = useQuery(["getNewsAll", page, filters], () => getNewsAll(fromAgent, page, filters), {
    keepPreviousData: true,
  });

  const track = useTrackingContext()

  useEffect(() => {
    if (data) {
      if (page === 1) {
        setNews(data["hydra:member"]);
      } else {
        setNews((prevState) => prevState.concat(data["hydra:member"]));
      }
      setHasMoreNews(page * itemsPerPage < data["hydra:totalItems"]);
    }
  }, [data, page]);

  const onFilter = (filters) => {
    setFilters(filters);
    setPage(1);

  };

  const fetchNews = () => {
    setPage(page + 1);
  };

  const handleReset = () => {
    setPage(1);
  };

  return (
    <Container maxWidth={false}>
      <Breadcrumbs />
      <GuardedComponent componentName="news_edit_page">
        <Box sx={{ mt: 3, mb: 3 }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            component={RouterLink}
            to={{ pathname: "/communication/news/create", state: { goBack: "/communication/news" } }}
          >
            Créer une actualité
          </Button>
        </Box>
      </GuardedComponent>

      <FilterPanel>
        <NewsToolbar onFilter={onFilter} />
      </FilterPanel>

      <Box sx={{ mt: 3 }}>
        <NewsList
          news={news}
          fetchNews={fetchNews}
          hasMoreNews={hasMoreNews}
          reset={handleReset}
          fromAgent={fromAgent}
        />
      </Box>

      {isFetching && <AppCircularProgress />}

      {!hasMoreNews && (
        <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
          <Typography variant="body1" color="text.disabled">
            Il n'y a plus d'actualité à afficher.
          </Typography>
        </Box>
      )}
    </Container>
  );
};

export default News;
