import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, CardContent, Divider, Grid, Stack, TextField } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { DatePicker, LoadingButton } from "@material-ui/lab";
import { Check as CheckIcon } from "@material-ui/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { postReminders } from "../../http/reminders";
import AutocompleteClientsField from "../AutocompleteClientsField";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/componentHelpers/trackings";

const AgentRemindersFormContent = ({ reminder }) => {
  const [client, setClient] = useState(reminder?.client ?? null);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const schema = yup.object().shape({
    title: yup.string().required(),
    content: yup.string(),
    date: yup.date().nullable().required(),
    prospect: yup.string(),
  });

  const track = useTrackingContext()

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      title: reminder?.title || "",
      content: reminder?.content || "",
      date: reminder?.date || null,
      prospect: reminder?.prospect || "",
    },
    resolver: yupResolver(schema),
  });

  const goBack = () => {
    if (history.location.state) {
      history.go(history.location.state.goBack);
    }
    history.goBack();
  };

  const onSubmit = async (data) => {
    track.click(ACTION_CLICK.CREATE_REMINDER)
    mutation.mutate({
      ...data,
      reminder,
      client: client,
    });
  };

  const mutation = useMutation(postReminders, {
    onSuccess: () => {
      if (reminder) {
        enqueueSnackbar("Le rappel a bien été modifié.", { variant: "success" });
        goBack();
      } else {
        enqueueSnackbar("Le rappel a bien été créé.", { variant: "success" });
        goBack();
      }
    },
  });

  const handleChangeClient = (val) => {
    if (val) {
      setClient(`/clients/${val}`);
    } else {
      setClient(null);
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <CardContent>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <Controller
              name="date"
              control={control}
              render={({ field, formState: { errors } }) => (
                <DatePicker
                  {...field}
                  label="Date du rappel"
                  inputFormat="DD/MM/YYYY"
                  shouldUnregister
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      fullWidth
                      error={Boolean(errors.date?.message)}
                      helperText={errors.date?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
        <Controller
          name="title"
          control={control}
          shouldUnregister
          render={({ field, formState: { errors } }) => (
            <TextField
              {...field}
              fullWidth
              label="Titre rappel"
              margin="normal"
              error={Boolean(errors.title?.message)}
              helperText={errors.title?.message}
            />
          )}
        />
        <Grid container>
          <Grid item xs={12} sm={4}>
            <AutocompleteClientsField
              setClient={(val) => handleChangeClient(val)}
              setData={(val) => setValue("prospect", val)}
              initialClient={client ? client.split("/")[2] : null}
              initialData={reminder?.prospect || ""}
              margin
              freeSolo
            />
          </Grid>
        </Grid>
        <Controller
          name="content"
          control={control}
          shouldUnregister
          render={({ field, formState: { errors } }) => (
            <TextField
              {...field}
              fullWidth
              label="Rappel (texte libre)"
              margin="normal"
              multiline
              minRows={10}
              error={Boolean(errors.content?.message)}
              helperText={errors.content?.message}
            />
          )}
        />
      </CardContent>
      <Divider />
      <Stack
        direction={{ xs: "column-reverse", sm: "row" }}
        spacing={2}
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button variant="outlined" onClick={goBack}>
          Annuler
        </Button>
        <LoadingButton loading={mutation.isLoading} variant="contained" type="submit" startIcon={<CheckIcon />}>
          Valider
        </LoadingButton>
      </Stack>
    </form>
  );
};

AgentRemindersFormContent.propTypes = {
  reminder: PropTypes.object,
};

export default AgentRemindersFormContent;
