import { Box, Paper } from "@material-ui/core";
import { Chart } from "react-google-charts";
import React from "react";
import { Download } from "@material-ui/icons";
import useAction from "../../../hooks/useAction";
import { LoadingButton } from "@material-ui/lab";

const DocumentsChart = ({ items, onExport }) => {
  const options = {
    title: "Documents consultés",
    chartArea: { width: "60%" },
    isStacked: true,
    hAxis: {
      title: "Nombre d'ouvertures",
      minValue: 0,
    },
    vAxis: {
      title: "Documents",
    },
  };

  const action = useAction();

  return (
    <Paper elevation={2} sx={{ position: "relative" }}>
      <div>
        <Chart
          chartType="BarChart"
          width="100%"
          height="600px"
          options={options}
          data={[
            ["Document", "Nombre d'ouvertures"],
            ...items.slice(0, 10).map((item) => [item.displayName ?? item.name, item.value]),
          ]}
        />
      </div>
      {onExport && (
        <Box sx={{ position: "absolute", top: "2%", right: "2%" }}>
          <LoadingButton
            loading={action.pending}
            startIcon={<Download/>}
            variant="contained"
            onClick={action.handle(() => onExport())}
          >
            Exporter
          </LoadingButton>
        </Box>
      )}
    </Paper>
  );
};

export default DocumentsChart;
