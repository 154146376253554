import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@material-ui/lab";
import PropTypes from "prop-types";
import React from "react";
import { Alert, Button, DialogActions, DialogContent, TextField, Divider } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import AppDialog from "../AppDialog";
import { postFolder } from "../../http/documentations";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/componentHelpers/trackings";

const CreateFolderModal = ({ open, title, onClose, person, type, queryToInvalidate, currentFolder }) => {
  const queryClient = useQueryClient();
  const schema = yup.object().shape({
    name: yup.string().required("Ce champ est obligatoire."),
  });

  const track = useTrackingContext()

  const {
    handleSubmit,
    formState: { errors },
    control,
    unregister,
  } = useForm({
    defaultValues: {
      name: "",
    },
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    unregister("name");
    onClose();
  };

  const onSubmit = async (data) => {
    const folder = {
      name: data.name,
      type: type,
      person: person["@id"],
      parentFolder: currentFolder["@id"],
    };

    track.click(ACTION_CLICK.DOCUMENT_CREATE_FOLDER)
    mutation.mutate(folder);
  };

  const mutation = useMutation(postFolder, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryToInvalidate);
      handleClose();
    },
  });

  return (
    <AppDialog open={open} onClose={handleClose} title={title}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          {mutation.isError ? <Alert severity="error">Une erreur est survenue : {mutation.error.message}</Alert> : null}

          <Controller
            name="name"
            control={control}
            variant="outlined"
            shouldUnregister="true"
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.name?.message)}
                helperText={errors.name?.message}
                fullWidth
                label="Titre"
                variant="outlined"
                margin="normal"
                autoComplete="name"
                type="text"
              />
            )}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Annuler
          </Button>
          <LoadingButton loading={mutation.isLoading} type="submit" variant="contained">
            Enregistrer
          </LoadingButton>
        </DialogActions>
      </form>
    </AppDialog>
  );
};

CreateFolderModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  person: PropTypes.object,
  queryToInvalidate: PropTypes.string,
  onClose: PropTypes.func,
  currentFolder: PropTypes.object,
};

export default CreateFolderModal;
