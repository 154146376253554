import React from "react";
import dayjs from "dayjs";
import { Box, Paper, Typography } from "@material-ui/core";

import AppCircularProgress from "../../AppCircularProgress";

const UniqueConnectionIndicator = ({ connection }) => {
  return (
    <Paper elevation={2}>
      {connection ? (
        <Box display="flex" justifyContent="space-evenly" gap="2rem" py="2rem">
          <Typography fontSize="1.2rem" fontWeight="600">
            Connexions uniques sur {dayjs().format("YYYY")} :{" "}
            {connection.unique}
          </Typography>
          <Typography fontSize="1.2rem" fontWeight="600">
            Connexions totales sur {dayjs().format("YYYY")} : {connection.total}
          </Typography>
        </Box>
      ) : (
        <AppCircularProgress />
      )}
    </Paper>
  );
};

export default UniqueConnectionIndicator;
