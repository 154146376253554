import { createContext, useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";

import store from "../store";
import { ACTION_CLICK, isUntrackedPage, TRACKING_TYPE } from "../utils/componentHelpers/trackings";
import { registerEvents } from "../http/tracking";
import { routes } from "../utils/routesBreadcumps";

const EVENT_LIMIT_TO_SEND = 5;

const TrackingContext = createContext({});

export const useTrackingContext = () => {
  const trackingContextValues = useContext(TrackingContext);

  if (!trackingContextValues)
    throw new Error(
      "You must use useTrackingContext in TrackingContextProvider",
    );

  return trackingContextValues;
};

const TrackingContextProvider = ({ children }) => {
  const eventsRef = useRef([]);
  const location = useLocation();

  const {
    auth: { sessionId },
  } = store.getState();

  useEffect(() => {
    eventsRef.current = [];
  }, [sessionId]);

  const checkAndSendEvents = async () => {
    if (eventsRef.current.length >= EVENT_LIMIT_TO_SEND) {
      const events = [...eventsRef.current];
      eventsRef.current = [];
      if (sessionId) await registerEvents(sessionId, events);
    }
  };

  const trackEvent = (type, action) => {
    if (isUntrackedPage(location.pathname)) return;

    eventsRef.current.push({
      type,
      action,
      path: location.pathname,
      timestamp: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    });

    void checkAndSendEvents();
  };

  useEffect(() => {
    const paths = location.pathname.split("/").filter((x) => x);

    let key = "";
    for (let index = 0; index < paths.length; index++) {
      const to = `/${paths.slice(0, index + 1).join("/")}`;
      key = Object.keys(routes).find((item) =>
        to.match(new RegExp(`^${item}$$`)),
      );
    }

    const route = routes[key];

    trackEvent(TRACKING_TYPE.PAGE, route?.section ?? "UNKNOWN");
  }, [location]);

  useEffect(() => {
    const listener = () => {
      void trackEvent(TRACKING_TYPE.CLICK, ACTION_CLICK.PAGINATION);
    };

    const root = document.getElementById("root");
    let elements = [];

    const observer = new MutationObserver(() => {
      for (const element of elements)
        element.removeEventListener("click", listener);

      elements = Array.from(
        document.querySelectorAll(".MuiTablePagination-actions>button"),
      );

      for (const element of elements)
        element.addEventListener("click", listener);
    });

    observer.observe(root, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, [location.pathname]);

  const track = {
    click: (action) => void trackEvent(TRACKING_TYPE.CLICK, action),
    document: (action) => void trackEvent(TRACKING_TYPE.DOCUMENT, action),
  };

  return (
    <TrackingContext.Provider value={track}>
      {children}
    </TrackingContext.Provider>
  );
};

export default TrackingContextProvider;
