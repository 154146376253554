import { yupResolver } from "@hookform/resolvers/yup";
import FormControl from "@material-ui/core/FormControl";
import { LoadingButton } from "@material-ui/lab";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Select,
  MenuItem,
  InputLabel,
  Divider,
  FormHelperText,
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import { removeProfile } from "../../http/profiles";
import AppDialog from "../AppDialog";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/componentHelpers/trackings";

const RemoveProfile = ({ profiles }) => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const queryClient = useQueryClient();
  const track = useTrackingContext();

  const schema = yup.object().shape({
    profileId: yup.string().required("Ce champ est obligatoire."),
  });

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      profileId: "",
    },
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    setValue("profileId", "");
    setOpen(false);
  };

  const onSubmit = async ({ profileId }) => {
    track.click(ACTION_CLICK.DELETE_PROFIL);
    mutation.mutate(profileId);
  };

  const mutation = useMutation(removeProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries("getProfiles");
      handleClose();
    },
  });

  return (
    <>
      <Button
        variant="contained"
        startIcon={<DeleteIcon />}
        onClick={handleClickOpen}
        color="hubnup"
        sx={{ marginLeft: "1rem" }}
      >
        Supprimer un profil
      </Button>
      <AppDialog title="Suppression d'un profil" onClose={handleClose} open={open}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>
              Sélectionner le profil à supprimer. Attention : Si un utilisateur est rattaché à ce profil il est
              impossible de supprimer celui-ci.
            </DialogContentText>

            {mutation.isError ? <Alert severity="error">Impossible de supprimer le profil.</Alert> : null}

            <Controller
              name="profileId"
              control={control}
              render={({ field, formState: { errors } }) => (
                <FormControl fullWidth variant="outlined" margin="normal" error={!!errors.profileId?.message}>
                  <InputLabel id="users-profile-label">Profil</InputLabel>
                  <Select {...field} label="Profil" labelId="users-profile-label">
                    {profiles.map((profile, index) => (
                      <MenuItem value={profile.id} key={index}>
                        {profile.title}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errors.profileId?.message}</FormHelperText>
                </FormControl>
              )}
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            <LoadingButton
              loading={mutation.isLoading}
              type="submit"
              variant="contained"
              color="hubnup"
              startIcon={<DeleteIcon />}
            >
              Supprimer
            </LoadingButton>
            <Button variant="contained" onClick={handleClose}>
              Annuler
            </Button>
          </DialogActions>
        </form>
      </AppDialog>
    </>
  );
};

RemoveProfile.propTypes = {
  profiles: PropTypes.array,
};

export default RemoveProfile;
