import React, { useState } from "react";
import { useQuery } from "react-query";
import { Autocomplete, CircularProgress, TextField } from "@material-ui/core";
import { searchSponsoredAgentByFullName } from "../http/agents";

const AutocompleteSponsoredAgent = ({ label, onChange, value }) => {
  const [inputValue, setInputValue] = useState("");

  const query = useQuery({
    queryKey: ["searchSponsoredAgentByFullName", inputValue],
    queryFn: () =>
      searchSponsoredAgentByFullName(inputValue).then((resp) => resp.data),
    enabled: inputValue.length > 3,
    placeholderData: [],
    staleTime: 1000 * 60,
  });

  return (
    <Autocomplete
      options={query.data}
      inputValue={inputValue}
      onInputChange={(_, value) => setInputValue(value)}
      getOptionLabel={(option) =>
        option ? `${option.lastName} ${option.firstName}` : ""
      }
      value={value}
      onChange={(_, value) => onChange(value)}
      autoComplete
      includeInputInList
      filterSelectedOptions
      loading={query.isFetching}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {query.isFetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteSponsoredAgent;
