import dayjs from "dayjs";
import React, { useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography,
} from "@material-ui/core";
import { Refresh as RefreshIcon, Search as SearchIcon } from "@material-ui/icons";
import { MobileDateRangePicker } from "@material-ui/lab";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toBigNumbers } from "../../utils/formatters";
import AutocompleteAgentsField from "../AutocompleteAgentsField";
import AutocompleteClientsField from "../AutocompleteClientsField";
import ValueLabelComponent from "../ValueLabelComponent";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/componentHelpers/trackings";

const InvoicesToolbar = ({ onFilter, fromAgent }) => {
  const [agent, setAgent] = useState("");
  const [client, setClient] = useState("");

  const schema = yup.object().shape({
    invoice: yup.string(),
    type: yup.string().oneOf(["", "Immobilier", "Placement"]),
    product: yup.string(),
    investmentAmount: yup.array().of(yup.number()),
    invoiceAmount: yup.array().of(yup.number()),
    createdAt: yup.array().of(yup.date().nullable()),
  });

  const track = useTrackingContext()

  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      invoice: "",
      product: "",
      type: "",
      investmentAmount: [0, 10000000],
      invoiceAmount: [0, 10000000],
      createdAt: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const filters = {
      ...data,
      agent: agent,
      client: client,
    };

    track.click(ACTION_CLICK.SET_FILTER)
    onFilter(filters);
  };

  const handleReset = () => {
    track.click(ACTION_CLICK.RESET_FILTER)
    reset()
  }

  const formatAmount = (value) => `${toBigNumbers(value)} €`;

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        {!fromAgent && (
          <Grid item xs={6} lg={3}>
            <AutocompleteAgentsField setAgent={setAgent} />
          </Grid>
        )}
        <Grid item xs={6} lg={3}>
          <AutocompleteClientsField setClient={setClient} />
        </Grid>
        <Grid item xs={6} lg={3}>
          <Controller
            name="product"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                label="Produit"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="investment-amount-label" shrink={true}>
              Montant investi
            </InputLabel>
            <Controller
              name="investmentAmount"
              control={control}
              onChange={(event, newValue) => setValue("investmentAmount", newValue)}
              render={({ field }) => (
                <Slider
                  sx={{ mt: 4 }}
                  min={0}
                  max={1000000}
                  step={10000}
                  valueLabelFormat={formatAmount}
                  components={{
                    ValueLabel: ValueLabelComponent,
                  }}
                  aria-labelledby="investment-amount-label"
                  valueLabelDisplay="auto"
                  {...field}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Controller
            name="createdAt"
            control={control}
            onChange={(event, newValue) => setValue("createdAt", newValue)}
            render={({ field }) => (
              <MobileDateRangePicker
                {...field}
                startText="Date d'édition du"
                endText="au"
                inputFormat="DD/MM/YYYY"
                allowSameDateSelection
                okText="Valider"
                cancelText="Annuler"
                toolbarTitle="Choisissez une plage de date"
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField
                      {...startProps}
                      fullWidth
                      variant="filled"
                      InputLabelProps={{ shrink: true }}
                      helperText=""
                    />
                    <Typography sx={{ mx: 2 }} variant="body1">
                      {" "}
                      -{" "}
                    </Typography>
                    <TextField
                      {...endProps}
                      fullWidth
                      variant="filled"
                      InputLabelProps={{ shrink: true }}
                      helperText=""
                    />
                  </>
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <Controller
            name="invoice"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Facture"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="invoice-type-label" shrink={true}>
              Type
            </InputLabel>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <Select {...field} labelId="invoice-type-label">
                  <MenuItem value="">
                    <em>Tous</em>
                  </MenuItem>
                  <MenuItem value="Immobilier">Immobilier</MenuItem>
                  <MenuItem value="Placement">Placement</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} lg={3}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="invoice-amount-label" shrink={true}>
              Montant facture
            </InputLabel>
            <Controller
              name="invoiceAmount"
              control={control}
              onChange={(event, newValue) => setValue("invoiceAmount", newValue)}
              render={({ field }) => (
                <Slider
                  sx={{ mt: 4 }}
                  min={0}
                  max={1000000}
                  step={10000}
                  valueLabelFormat={formatAmount}
                  components={{
                    ValueLabel: ValueLabelComponent,
                  }}
                  aria-labelledby="invoice-amount-label"
                  valueLabelDisplay="auto"
                  {...field}
                />
              )}
            />
          </FormControl>
        </Grid>
        {!fromAgent && <Grid item display={{ xs: "none", lg: "block" }} lg={9} />}
        <Grid item xs={6} lg={3} container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Button fullWidth variant="contained" startIcon={<SearchIcon />} type="submit">
              Filtrer
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth variant="outlined" startIcon={<RefreshIcon />} onClick={handleReset}>
              Réinitialiser
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default InvoicesToolbar;
