export const ROUTES_SECTION = {
  HOME: "HOME",
  GLOBAL: "GLOBAL",
  AGENT: "AGENT",
  SALES: "SALES",
  TEAM: "TEAM",
  CUSTOMERS: "CUSTOMERS",
  INVOICES: "INVOICES",
  COMMUNICATION: "COMMUNICATION",
  LIBRARY: "LIBRARY",
  ADMIN: "ADMIN",
  MY_SPACE: "MY_SPACE",
  MY_SALES: "MY_SALES",
  MY_TEAM: "MY_TEAM",
  MY_CUSTOMERS: "MY_CUSTOMERS",
  NEWS: "NEWS",
  TRACKING: "TRACKING",
};

export const routes = {
  // shared
  "/login": {
    label: "Connexion",
    isLink: true,
    section: ROUTES_SECTION.GLOBAL,
  },
  "/logout": {
    label: "Déconnexion",
    isLink: true,
    section: ROUTES_SECTION.GLOBAL,
  },

  // agent
  "/my": {
    label: "Mon espace",
    isLink: false,
    section: ROUTES_SECTION.MY_SPACE,
  },
  "/my/account": {
    label: "Mon compte",
    isLink: true,
    section: ROUTES_SECTION.MY_SPACE,
  },
  "/my/account/edit": {
    label: "Modifier mes informations",
    isLink: true,
    section: ROUTES_SECTION.MY_SPACE,
  },
  "/my/reminders": {
    label: "Mes rappels",
    isLink: true,
    section: ROUTES_SECTION.MY_SPACE,
  },
  "/my/reminders/reporting": {
    label: "Mes rappels",
    isLink: true,
    section: ROUTES_SECTION.MY_SPACE,
  },
  "/my/training": {
    label: "Mes formations",
    isLink: true,
    section: ROUTES_SECTION.MY_SPACE,
  },
  "/my-invoices": {
    label: "Mes factures",
    isLink: true,
    section: ROUTES_SECTION.MY_SPACE,
  },
  "/my-invoices/[a-fA-F0-9-]*": {
    label: "Détail client",
    isLink: true,
    section: ROUTES_SECTION.MY_SPACE,
  },
  "/my-sales": {
    label: "Mes ventes",
    isLink: true,
    section: ROUTES_SECTION.MY_SALES,
  },
  "/my-sales/summary": {
    label: "Synthèse",
    isLink: true,
    section: ROUTES_SECTION.MY_SALES,
  },
  "/my-sales/real-estate": {
    label: "Suivi immobilier",
    isLink: true,
    section: ROUTES_SECTION.MY_SALES,
  },
  "/my-sales/real-estate/clients": {
    label: "Détail client",
    isLink: true,
    section: ROUTES_SECTION.MY_SALES,
  },
  "/my-sales/investment": {
    label: "Suivi placement",
    isLink: true,
    section: ROUTES_SECTION.MY_SALES,
  },
  "/my-sales/investment/clients": {
    label: "Détail client",
    isLink: true,
    section: ROUTES_SECTION.MY_SALES,
  },
  "/my-sales/vlp": {
    label: "Suivi VLP",
    isLink: true,
    section: ROUTES_SECTION.MY_SALES,
  },
  "/my-sales/vlp/clients": {
    label: "Détail client",
    isLink: true,
    section: ROUTES_SECTION.MY_SALES,
  },
  "/my-team": {
    label: "Mon équipe",
    isLink: true,
    section: ROUTES_SECTION.MY_TEAM,
  },
  "/my-team/[a-fA-F0-9-]*": {
    label: "Détail mandataire",
    isLink: true,
    section: ROUTES_SECTION.MY_TEAM,
  },
  "/my-team/[a-fA-F0-9-]*/team": {
    label: "Equipe",
    isLink: true,
    section: ROUTES_SECTION.MY_TEAM,
  },
  "/my-team/[a-fA-F0-9-]*/real-estate": {
    label: "Suivi immobilier",
    isLink: true,
    section: ROUTES_SECTION.MY_TEAM,
  },
  "/team/[a-fA-F0-9-]*/state": {
    label: "Suivi parrainage",
    isLink: true,
    section: ROUTES_SECTION.MY_TEAM
  },
  "/my-team/[a-fA-F0-9-]*/investment": {
    label: "Equipe",
    isLink: true,
    section: ROUTES_SECTION.MY_TEAM,
  },
  "/my-team-sponsor": {
    section: ROUTES_SECTION.MY_TEAM,
  },
  "/my-training-team": {
    label: "Formations équipe",
    isLink: true,
    section: ROUTES_SECTION.MY_TEAM,
  },
  "/my-customers": {
    label: "Mes clients",
    isLink: true,
    section: ROUTES_SECTION.MY_CUSTOMERS,
  },
  "/my-prospects": {
    label: "Mes prospects",
    isLink: true,
    section: ROUTES_SECTION.MY_CUSTOMERS,
  },
  "/my-customers/.*": {
    label: "Détail client",
    isLink: true,
    section: ROUTES_SECTION.MY_CUSTOMERS,
  },
  "/news": {
    label: "Actualités Hubn'UP",
    isLink: true,
    section: ROUTES_SECTION.NEWS,
  },
  "/news/[a-fA-F0-9-]*": {
    section: ROUTES_SECTION.NEWS,
  },

  // hubnup
  "/administration": {
    label: "Administration",
    isLink: true,
    section: ROUTES_SECTION.ADMIN,
  },
  "/administration/portail": {
    label: "Portail",
    isLink: true,
    section: ROUTES_SECTION.ADMIN,
  },
  "/administration/users": {
    label: "Utilisateurs",
    isLink: true,
    section: ROUTES_SECTION.ADMIN,
  },
  "/communication": {
    label: "Communication",
    isLink: true,
    section: ROUTES_SECTION.COMMUNICATION,
  },
  "/communication/news": {
    label: "Actualités",
    isLink: true,
    section: ROUTES_SECTION.COMMUNICATION,
  },
  "/communication/pictures-library": {
    label: "Bibliothèque d'images",
    isLink: true,
    section: ROUTES_SECTION.COMMUNICATION,
  },
  "/communication/reminders": {
    label: "Rappels",
    isLink: true,
    section: ROUTES_SECTION.COMMUNICATION,
  },
  "/communication/notifications": {
    label: "Notifications",
    isLink: true,
    section: ROUTES_SECTION.COMMUNICATION,
  },
  "/sales": { label: "Ventes", isLink: true, section: ROUTES_SECTION.SALES },
  "/sales/real-estate": {
    label: "Immobilier",
    isLink: true,
    section: ROUTES_SECTION.SALES,
  },
  "/sales/real-estate/team/[a-fA-F0-9-]*": {
    label: "Détail mandataire",
    isLink: true,
    section: ROUTES_SECTION.SALES,
  },
  "/sales/real-estate/team/[a-fA-F0-9-]*/team": {
    label: "Equipe",
    isLink: true,
    section: ROUTES_SECTION.SALES,
  },
  "/sales/real-estate/team/[a-fA-F0-9-]*/real-estate": {
    label: "Suivi immobilier",
    isLink: true,
    section: ROUTES_SECTION.SALES,
  },
  "/sales/real-estate/team/[a-fA-F0-9-]*/investment": {
    label: "Suivi placement",
    isLink: true,
    section: ROUTES_SECTION.SALES,
  },
  "/sales/real-estate/clients/[a-fA-F0-9-]*": {
    label: "Détail client",
    isLink: true,
    section: ROUTES_SECTION.SALES,
  },
  "/sales/investment": {
    label: "Placement",
    isLink: true,
    section: ROUTES_SECTION.SALES,
  },
  "/sales/investment/team/[a-fA-F0-9-]*": {
    label: "Détail mandataire",
    isLink: true,
    section: ROUTES_SECTION.SALES,
  },
  "/sales/investment/team/[a-fA-F0-9-]*/team": {
    label: "Equipe",
    isLink: true,
    section: ROUTES_SECTION.SALES,
  },
  "/sales/investment/team/[a-fA-F0-9-]*/real-estate": {
    label: "Suivi immobilier",
    isLink: true,
    section: ROUTES_SECTION.SALES,
  },
  "/sales/investment/team/[a-fA-F0-9-]*/investment": {
    label: "Suivi placement",
    isLink: true,
    section: ROUTES_SECTION.SALES,
  },
  "/sales/investment/clients/[a-fA-F0-9-]*": {
    label: "Détail client",
    isLink: true,
    section: ROUTES_SECTION.SALES,
  },
  "/sales/vlp": { label: "VLP", isLink: true, section: ROUTES_SECTION.SALES },
  "/sales/vlp/team/[a-fA-F0-9-]*": {
    label: "Détail mandataire",
    isLink: true,
    section: ROUTES_SECTION.SALES,
  },
  "/sales/vlp/team/[a-fA-F0-9-]*/team": {
    label: "Equipe",
    isLink: true,
    section: ROUTES_SECTION.SALES,
  },
  "/sales/vlp/team/[a-fA-F0-9-]*/real-estate": {
    label: "Suivi immobilier",
    isLink: true,
    section: ROUTES_SECTION.SALES,
  },
  "/sales/vlp/team/[a-fA-F0-9-]*/investment": {
    label: "Suivi placement",
    isLink: true,
    section: ROUTES_SECTION.SALES,
  },
  "/sales/vlp/clients/[a-fA-F0-9-]*": {
    label: "Détail client",
    isLink: true,
    section: ROUTES_SECTION.SALES,
  },
  "/customers": {
    label: "Clients",
    isLink: true,
    section: ROUTES_SECTION.CUSTOMERS,
  },
  "/customers/[a-fA-F0-9-]*": {
    label: "Détail client",
    isLink: true,
    section: ROUTES_SECTION.CUSTOMERS,
  },
  "/customers/[a-fA-F0-9-]*/team/[a-fA-F0-9-]*": {
    label: "Détail mandataire",
    isLink: true,
    section: ROUTES_SECTION.CUSTOMERS,
  },
  "/customers/[a-fA-F0-9-]*/team/[a-fA-F0-9-]*/team": {
    label: "Equipe",
    isLink: true,
    section: ROUTES_SECTION.CUSTOMERS,
  },
  "/customers/[a-fA-F0-9-]*/team/[a-fA-F0-9-]*/real-estate": {
    label: "Suivi immobilier",
    isLink: true,
    section: ROUTES_SECTION.CUSTOMERS,
  },
  "/customers/[a-fA-F0-9-]*/team/[a-fA-F0-9-]*/investment": {
    label: "Suivi placement",
    isLink: true,
    section: ROUTES_SECTION.CUSTOMERS,
  },
  "/prospects": {
    label: "Prospects",
    isLink: true,
    section: ROUTES_SECTION.CUSTOMERS,
  },
  "/prospects/[a-fA-F0-9-]*": {
    label: "Détail prospect",
    isLink: true,
    section: ROUTES_SECTION.CUSTOMERS,
  },
  "/prospect/team/[a-fA-F0-9-]*": {
    label: "Détail mandataire",
    isLink: true,
    section: ROUTES_SECTION.CUSTOMERS,
  },
  "/prospect/team/[a-fA-F0-9-]*/team": {
    label: "Equipe",
    isLink: true,
    section: ROUTES_SECTION.CUSTOMERS,
  },
  "/prospect/team/[a-fA-F0-9-]*/real-estate": {
    label: "Suivi immobilier",
    isLink: true,
    section: ROUTES_SECTION.CUSTOMERS,
  },
  "/prospect/team/[a-fA-F0-9-]*/investment": {
    label: "Suivi placement",
    isLink: true,
    section: ROUTES_SECTION.CUSTOMERS,
  },
  "/team": { label: "Mandataires", isLink: true, section: ROUTES_SECTION.TEAM },
  "/team/[a-fA-F0-9-]*": {
    label: "Détail mandataire",
    isLink: true,
    section: ROUTES_SECTION.TEAM,
  },
  "/team/[a-fA-F0-9-]*/team": {
    label: "Equipe",
    isLink: true,
    section: ROUTES_SECTION.TEAM,
  },
  "/team/[a-fA-F0-9-]*/real-estate": {
    label: "Suivi immobilier",
    isLink: true,
    section: ROUTES_SECTION.TEAM,
  },
  "/team/[a-fA-F0-9-]*/investment": {
    label: "Suivi placement",
    isLink: true,
    section: ROUTES_SECTION.TEAM,
  },
  "/invoices": {
    label: "Factures",
    isLink: true,
    section: ROUTES_SECTION.INVOICES,
  },
  "/invoices/[a-fA-F0-9-]*/clients": {
    label: "Détail client",
    isLink: true,
    section: ROUTES_SECTION.INVOICES,
  },
  "/invoices/[a-fA-F0-9-]*/mandataire": {
    label: "Détails Mandataire",
    isLink: true,
    section: ROUTES_SECTION.INVOICES,
  },
  "/tracking/global": {
    label: "Suivi statistique global",
    isLink: true,
    section: ROUTES_SECTION.TRACKING,
  },
  "/tracking/users": {
    label: "Liste des consultants",
    isLink: true,
    section: ROUTES_SECTION.TRACKING,
  },
  "/tracking/[a-fA-F0-9-]*/details": {
    label: "Suivi statistique par consultant",
    isLink: true,
    section: ROUTES_SECTION.TRACKING,
  },

  "/library/bibliotheque": {
    section: ROUTES_SECTION.LIBRARY,
  }
};
