import React, { useMemo } from "react";
import dayjs from "dayjs";
import { Paper } from "@material-ui/core";
import { Chart } from "react-google-charts";

import { DEVICE_NAMES } from "../../../utils/componentHelpers/trackings";

const DevicesClickCharts = ({ items }) => {
  const options = {
    title: "Nombre de clic par jour et par appareil (les 10 derniers jours)",
    chartArea: { width: "60%" },
    isStacked: true,
    hAxis: {
      title: "Nombre de clics",
      minValue: 0,
    },
    vAxis: {
      title: "Jour",
    },
  };

  const [data, columns] = useMemo(() => {
    const devices = Array.from(new Set(items.map((item) => item.device)));
    const dates = new Set(items.map((item) => item.date));

    const data = [];
    for (const date of Array.from(dates)) {
      const dateItems = items.filter((search) => search.date === date);

      const row = [dayjs(date).format("DD/MM/YY")];

      for (const device of devices) {
        row.push(
          dateItems.find((search) => search.device === device)?.value ?? 0,
        );
      }

      data.push(row);
    }

    return [data, devices.map((item) => DEVICE_NAMES[item] ?? item)];
  }, [items]);

  return (
    <Paper elevation={2}>
      <Chart
        chartType="BarChart"
        width="100%"
        height="600px"
        options={options}
        data={[["Jour", ...columns], ...data]}
      />
    </Paper>
  );
};

export default DevicesClickCharts;
