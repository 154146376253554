import { Stack } from "@material-ui/core";
import { useQuery } from "react-query";

import {
  exportClick,
  exportPages,
  retrievePageByWeek,
  retrievePagesByClick,
} from "../../../http/tracking";
import React from "react";
import TopPagesChart from "../charts/TopPagesChart";
import TopPagesByClick from "../charts/TopPagesByClick";

const TrackingPages = () => {
  const pagesQuery = useQuery({
    queryKey: ["tracking", "pages"],
    queryFn: () => retrievePageByWeek(),
  });

  const pagesByClicksQuery = useQuery({
    queryKey: ["tracking", "pages-by-click"],
    queryFn: () => retrievePagesByClick(),
  });

  return (
    <Stack spacing="2rem">
      <TopPagesChart items={pagesQuery.data} onExport={() => exportPages()} />
      <TopPagesByClick
        items={pagesByClicksQuery.data}
        onExport={() => exportClick()}
      />
    </Stack>
  );
};

export default TrackingPages;
