import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { FileDownload } from "@material-ui/icons";
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import {
  exportTop50ConsutantsByClick,
  exportTop50Documents,
  exportTop50PagesByClick,
  retrieveTop50ConsutantsByClick,
  retrieveTop50Documents,
  retrieveTop50PagesByClick,
} from "../../../http/tracking";
import { routes } from "../../../utils/routesBreadcumps";
import { isUntrackedPage } from "../../../utils/componentHelpers/trackings";

const getLabel = (pathname) => {
  const key = Object.keys(routes).find((item) =>
    pathname.match(new RegExp(`^${item}$$`)),
  );

  if (key) return routes[key].label ?? key;

  return pathname;
};

const Top50 = () => {
  const topConsultantsQuery = useQuery({
    queryKey: ["tracking", "top", "consultants"],
    queryFn: () => retrieveTop50ConsutantsByClick(),
    retry: 0,
  });

  const topPagesQuery = useQuery({
    queryKey: ["tracking", "top", "pages"],
    queryFn: () => retrieveTop50PagesByClick(),
    retry: 0,
  });

  const topDocumentsQuery = useQuery({
    queryKey: ["tracking", "top", "documents"],
    queryFn: () => retrieveTop50Documents(),
    retry: 0,
  });

  const topPages = useMemo(() => {
    if (!topPagesQuery.data) return [];

    return topPagesQuery.data
      .filter((item) => !isUntrackedPage(item.name))
      .map((item) => ({ ...item, name: getLabel(item.name) }));
  }, [topPagesQuery.data]);

  const topDocument = useMemo(() => {
    if (!topDocumentsQuery.data) return [];

    return topDocumentsQuery.data.map((item) => ({
      ...item,
      name: item.displayName ?? item.name,
    }));
  }, [topDocumentsQuery.data]);

  return (
    <Grid container spacing="3rem">
      <Grid item xs={12} sm={12} md={4}>
        {!topConsultantsQuery.isError ? (
          <TopList
            title="Top 50 mandataires"
            subTitle="(en nombre de clics)"
            columns={["Consultant", "clics"]}
            items={topConsultantsQuery.data}
            loading={topConsultantsQuery.isLoading}
            onExport={() => exportTop50ConsutantsByClick()}
          />
        ) : (
          <Alert severity="error">Une erreur est survenue</Alert>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        {!topPagesQuery.isError ? (
          <TopList
            title="Top 50 pages consultées"
            subTitle="(en nombre de pages)"
            columns={["Page", "clics"]}
            items={topPages}
            loading={topPagesQuery.isLoading}
            onExport={() => exportTop50PagesByClick()}
          />
        ) : (
          <Alert severity="error">Une erreur est survenue</Alert>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        {!topDocumentsQuery.isError ? (
          <TopList
            title="Top 50 documents consultés"
            subTitle="(en nombre d'ouverture)"
            columns={["Document", "Téléchargement"]}
            items={topDocument}
            loading={topDocumentsQuery.isLoading}
            onExport={() => exportTop50Documents()}
          />
        ) : (
          <Alert severity="error">Une erreur est survenue</Alert>
        )}
      </Grid>
    </Grid>
  );
};

const TopList = ({ items, title, subTitle, loading, columns, onExport }) => {
  return (
    <Card>
      <CardHeader
        title={title}
        subheader={subTitle}
        action={
          onExport && (
            <Button
              onClick={onExport}
              variant="contained"
              startIcon={<FileDownload/>}
            >
              Exporter
            </Button>
          )
        }
      />
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Classement</TableCell>
                {columns.map((column, index) => (
                  <TableCell key={index}>{column}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading
                ? Array.from({ length: 50 }).map((_, index) => (
                  <TableRow>
                    <TableCell>#{index + 1}</TableCell>
                    <TableCell>
                      <Skeleton/>
                    </TableCell>
                    <TableCell>
                      <Skeleton/>
                    </TableCell>
                  </TableRow>
                ))
                : items.map((item, index) => (
                  <TableRow>
                    <TableCell>#{index + 1}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.value}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default Top50;
