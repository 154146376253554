import React from "react";
import { useQuery } from "react-query";
import { Stack } from "@material-ui/core";

import {
  exportSession,
  retrieveConnexionByDays,
  retrieveDeviceClick,
  retrieveUniqueConnectionByYear,
} from "../../../http/tracking";
import LastDaysConnectionsChart from "../charts/LastDaysConnectionsChart";
import UniqueConnectionIndicator from "../charts/UniqueConnectionIndicator";
import DevicesClickCharts from "../charts/DevicesClickCharts";

const TrackingConnections = () => {
  const deviceQuery = useQuery({
    queryKey: ["tracking", "device"],
    queryFn: () => retrieveDeviceClick(),
  });

  const uniqueConnectionQuery = useQuery({
    queryKey: ["tracking", "unique-query"],
    queryFn: () => retrieveUniqueConnectionByYear(),
  });

  const connectionsQuery = useQuery({
    queryKey: ["tracking", "connections-by-days"],
    queryFn: () => retrieveConnexionByDays(),
  });

  return (
    <Stack gap="2rem">
      <UniqueConnectionIndicator
        connection={uniqueConnectionQuery.data}
        onExport={() => exportSession()}
      />
      {deviceQuery.isSuccess && <DevicesClickCharts items={deviceQuery.data} />}
      {connectionsQuery.isSuccess && (
        <LastDaysConnectionsChart
          items={connectionsQuery.data}
          onExport={() => exportSession()}
        />
      )}
    </Stack>
  );
};

export default TrackingConnections;
