import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { Refresh as RefreshIcon, Search as SearchIcon } from "@material-ui/icons";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MobileDateRangePicker } from "@material-ui/lab";
import AutocompleteAgentsField from "../AutocompleteAgentsField";
import AutocompleteClientsField from "../AutocompleteClientsField";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/componentHelpers/trackings";

const VlpToolbar = ({ onFilter, fromAgent, exercice }) => {
  const actualYear = new Date().getFullYear();
  const [agent, setAgent] = useState("");
  const [client, setClient] = useState("");
  const [resetFields, setResetFields] = useState(false);
  const defaultValues = {
    dateImportPayplan: [null, null],
    exercice: exercice,
  };

  const track = useTrackingContext()

  const schema = yup.object().shape({
    dateImportPayplan: yup.array().of(yup.date().nullable()),
  });

  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const filters = {
      ...data,
      agent: agent,
      client: client,
      exercice: exercice,
    };

    onFilter(filters);
    track.click(ACTION_CLICK.SET_FILTER)
  };

  const onReset = () => {
    setAgent("");
    setClient("");
    setResetFields(!resetFields);
    reset();
    onFilter({
      ...defaultValues,
      agent: "",
      client: "",
    });
    track.client(ACTION_CLICK.RESET_FILTER)
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        {!fromAgent && (
          <Grid item xs={6} lg={4}>
            <AutocompleteAgentsField setAgent={setAgent} reset={resetFields} />
          </Grid>
        )}
        <Grid item xs={6} lg={4}>
          <AutocompleteClientsField setClient={setClient} reset={resetFields} />
        </Grid>
        <Grid item xs={6} lg={4}>
          <Controller
            name="dateImportPayplan"
            control={control}
            onChange={(event, newValue) => setValue("dateImportPayplan", newValue)}
            render={({ field }) => (
              <MobileDateRangePicker
                {...field}
                startText="Date de traitement"
                endText="au"
                inputFormat="DD/MM/YYYY"
                allowSameDateSelection
                okText="Valider"
                cancelText="Annuler"
                toolbarTitle="Choisissez une plage de date"
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField
                      {...startProps}
                      fullWidth
                      variant="filled"
                      InputLabelProps={{ shrink: true }}
                      helperText=""
                    />
                    <Typography sx={{ mx: 2 }} variant="body1">
                      {" "}
                      -{" "}
                    </Typography>
                    <TextField
                      {...endProps}
                      fullWidth
                      variant="filled"
                      InputLabelProps={{ shrink: true }}
                      helperText=""
                    />
                  </>
                )}
              />
            )}
          />
        </Grid>
        {!fromAgent && <Grid item display={{ xs: "none", lg: "block" }} lg={8} />}
        {fromAgent && <Grid item display={{ xs: "none", lg: "block" }} lg={8} />}
        <Grid item xs={6} lg={4} container spacing={2} alignItems="end">
          <Grid item xs={6}>
            <Button fullWidth variant="contained" startIcon={<SearchIcon />} type="submit">
              Filtrer
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth variant="outlined" startIcon={<RefreshIcon />} onClick={() => onReset()}>
              Réinitialiser
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

VlpToolbar.defaultProps = {
  fromAgent: false,
};

VlpToolbar.propTypes = {
  onFilter: PropTypes.func,
  fromAgent: PropTypes.bool,
  exercice: PropTypes.string,
};

export default VlpToolbar;
