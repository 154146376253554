import React, { useEffect, useRef } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { useQuery } from "react-query";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  List,
  Typography,
} from "@material-ui/core";
import checkAccess from "../../utils/checkAccess";
import checkAccessStore from "../../utils/checkAccessStore";
import NavItem from "./NavItem";
import ImpersonateUserMenuInfo from "./ImpersonateUserMenuInfo";
import SocialMenuLink from "./SocialMenuLink";
import AvatarWithTwoBadges from "./notifications/AvatarWithTwoBadges";
import NotificationContent from "./notifications/NotificationContent";
import useToggle from "../../hooks/useToggle";
import {
  agentMenusRoles,
  hubnupMenusRoles,
} from "../../utils/ressources/dashboardSideBar";
import store from "../../store";
import { getNotificationListByUser } from "../../http/notifications";
import useClickAway from "../../hooks/useClickAway";

const DashboardSidebar = ({
  fullName,
  picture,
  roles,
  userComponents,
  onMobileClose,
  openMobile,
  circularPicture,
  consultantSharepointLink,
  menuLinks,
}) => {
  const location = useLocation();
  const formRef = useRef(null);

  // Manage Notification for Mobile - Tablet
  const {
    isDisplayed: isNotificationDisplayed,
    toggleAction: toggleActionNotification,
  } = useToggle();

  const refMobileAndTablet = useRef(null);

  useClickAway(refMobileAndTablet, toggleActionNotification);

  // Manage Notification for Desktop
  const {
    isDisplayed: isNotificationDisplayedDesktop,
    toggleAction: toggleActionNotificationDesktop,
  } = useToggle();

  const refDesktop = useRef(null);

  useClickAway(refDesktop, toggleActionNotificationDesktop);

  const {
    auth: { email },
  } = store.getState();

  const { data: userNotificationsData, isLoading } = useQuery({
    queryKey: ["notifications-list", { email }],
    queryFn: () => getNotificationListByUser(email),
  });

  // ------------------- Menu Links

  let menus = hubnupMenusRoles;

  if (roles.some((role) => role.includes("ROLE_MANDATAIRE"))) {
    const addHabilitation = menuLinks?.find(
      (menuLink) => menuLink.name === "add_habilitation",
    );
    const teamSponsoring = menuLinks?.find(
      (menuLink) => menuLink.name === "team_sponsoring",
    );
    menus = agentMenusRoles(
      consultantSharepointLink,
      addHabilitation,
      teamSponsoring,
    );
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const handleFdoAuth = () => {
    formRef.current.submit();
  };

  const getUserId = () => {
    const auth = JSON.parse(localStorage.getItem("auth"));

    return auth?.id;
  };

  const content = (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
        p={2}
      >
        {checkAccessStore("account_modifications_page") ? (
          /* can see and edit account profile */
          <AvatarWithTwoBadges
            toggleActionNotificationDesktop={toggleActionNotificationDesktop}
            toggleActionNotification={toggleActionNotification}
            userNotificationsData={userNotificationsData}
          >
            <Avatar
              alt={fullName}
              component={RouterLink}
              src={picture}
              style={{
                cursor: "pointer",
                width: 64,
                height: 64,
              }}
              to={{
                pathname: "/my/account/edit",
                state: { goBack: location.pathname },
              }}
              variant={circularPicture ? "circular" : "rounded"}
            />
          </AvatarWithTwoBadges>
        ) : (
          <AvatarWithTwoBadges
            toggleActionNotificationDesktop={toggleActionNotificationDesktop}
            toggleActionNotification={toggleActionNotification}
            userNotificationsData={userNotificationsData}
          >
            <Avatar
              alt={fullName}
              component={RouterLink}
              src={picture}
              style={{
                cursor: "pointer",
                width: 64,
                height: 64,
              }}
            />
          </AvatarWithTwoBadges>
        )}
        <Box mt={1}>
          <Typography color="textPrimary" variant="h5">
            {fullName}
          </Typography>
        </Box>
      </Box>
      <Divider />
      {menus.map((menu, index) =>
        checkAccess(menu.name, userComponents) ? (
          <Box key={index}>
            <Box p={2}>
              <List>
                {menu.map((item) =>
                  item.type && item.type === "external_link" ? (
                    checkAccess(item.name, userComponents) ? (
                      <NavItem
                        href={item.href}
                        key={item.title}
                        title={item.title}
                        icon={item.icon}
                        children={item.children}
                        externalLink
                      />
                    ) : null
                  ) : checkAccess(item.name, userComponents) ? (
                    <NavItem
                      href={item.href}
                      key={item.title}
                      title={item.title}
                      icon={item.icon}
                      children={item.children}
                      onClick={handleFdoAuth}
                    />
                  ) : null,
                )}
              </List>
            </Box>
            {index !== menus.length - 1 && <Divider />}
            {index === menus.length - 1 && (
              <Divider sx={{ display: { xs: "flex", lg: "none" } }} />
            )}
          </Box>
        ) : null,
      )}

      <Box
        sx={{
          padding: 0,
          display: { xs: "flex", lg: "none" },
          flexDirection: "column",
          alignItems: "center",
          marginRight: "24px",
        }}
      >
        <ImpersonateUserMenuInfo />
      </Box>
      <Box
        sx={{
          display: { xs: "flex", lg: "none" },
          alignSelf: "center",
          marginTop: "20px",
        }}
      >
        <SocialMenuLink fromSidebar />
      </Box>

      <form
        action={process.env.REACT_APP_OZIOLAB_FDOPROXY}
        method="post"
        target="_blank"
        ref={formRef}
        style={{ display: "none" }}
      >
        <input type="text" name="fdoapikey" value={getUserId()} readOnly />
      </form>
    </Box>
  );

  return (
    <>
      {/* Desktop */}
      <Box sx={{ display: { xs: "none", lg: "flex" } }}>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            style: {
              width: 256,
              top: 64,
              height: "calc(100% - 64px)",
            },
          }}
        >
          {content}
        </Drawer>
      </Box>
      {/* Tablet and Mobile */}
      <Box sx={{ display: { lg: "none", xs: "flex" } }}>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            style: {
              width: 256,
            },
          }}
        >
          {content}
          {/* if Mobile and tablet (Menu Hidden) */}
          {isNotificationDisplayed && (
            <div ref={refMobileAndTablet}>
              <NotificationContent
                toggleAction={toggleActionNotification}
                userNotificationsData={userNotificationsData}
                isLoading={isLoading}
              />
            </div>
          )}
        </Drawer>
      </Box>
      {/* if Desktop : i display the NotificationContent */}
      {isNotificationDisplayedDesktop && (
        <div ref={refDesktop}>
          <NotificationContent
            toggleAction={toggleActionNotificationDesktop}
            userNotificationsData={userNotificationsData}
            isLoading={isLoading}
          />
        </div>
      )}
    </>
  );
};

DashboardSidebar.propTypes = {
  fullName: PropTypes.string,
  picture: PropTypes.string,
  roles: PropTypes.array,
  userComponents: PropTypes.array,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

const mapStateToProps = (state) => ({
  fullName: state.auth.fullName,
  picture: state.auth.picture,
  roles: state.auth.roles,
  userComponents: state.auth.components,
  circularPicture: state.auth.circularPicture,
  consultantSharepointLink: state.auth.consultantSharepointLink,
  menuLinks: state.auth.menuLinks,
});

export default connect(mapStateToProps)(DashboardSidebar);
