import { useState } from "react";

export default function useAction(options = {}) {
  const [pending, setPending] = useState(options.defaultPending ?? false);

  const handle = (callback) => {
    return async (...args) => {
      try {
        setPending(true);
        options.withDelay &&
          (await new Promise((r) => setTimeout(r, options.delay ?? 200)));
        await Promise.resolve(callback(...args));
      } catch (error) {
        throw error;
      } finally {
        setPending(false);
      }
    };
  };

  return {
    pending,
    handle,
    toggle: (value) => setPending(value ? Boolean(value) : pending),
  };
}
