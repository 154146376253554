import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Box, Card, Container } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { useHistory } from "react-router-dom";

import { getUsersAll } from "../../http/user";
import Breadcrumbs from "../../components/Breadcrumbs";
import { useHydra } from "../../hooks/useHydra";
import FilterPanel from "../../components/FilterPanel";
import UsersToolbar from "../../components/user/UsersToolbar";
import { getProfiles } from "../../http/profiles";

const TrackingUsersList = () => {
  const [page, setPage] = useState(0);
  const [sortModel, setSortModel] = useState([
    { field: "lastName", sort: "asc" },
  ]);
  const [filters, setFilters] = useState({});

  const query = useQuery({
    queryKey: ["test", sortModel, page, filters],
    queryFn: () => getUsersAll(filters, page, sortModel),
  });

  const [users, { total }] = useHydra(query.data);

  const columns = [
    {
      field: "lastName",
      headerName: "Nom",
      flex: 1,
    },
    {
      field: "firstName",
      headerName: "Prénom",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
  ];

  const handleSortModelChange = (params) => {
    if (params.sortModel !== sortModel) {
      setSortModel(params.sortModel);
    }
  };

  const history = useHistory();

  const handleClick = (cell) => {
    history.push(`/tracking/users/${cell.id}/details`);
  };

  const profilesQuery = useQuery(["getProfiles"], () => getProfiles());

  useEffect(() => console.log("filters", filters), [filters]);

  return (
    <Container maxWidth={false}>
      <Breadcrumbs />
      <FilterPanel sx={{ mt: 2 }}>
        <UsersToolbar onFilter={setFilters} profiles={profilesQuery.data} />
      </FilterPanel>

      <Box sx={{ mt: 2 }}>
        <Card>
          <DataGrid
            columns={columns}
            rows={users}
            sortModel={sortModel}
            loading={query.isLoading}
            autoHeight
            rowCount={total}
            pageSize={10}
            disableColumnFilter
            disableSelectionOnClick
            disableColumnMenu
            disableColumnReorder
            pagination
            paginationMode="server"
            onPageChange={(params) => setPage(params.page)}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            onCellClick={handleClick}
          />
        </Card>
      </Box>
    </Container>
  );
};

export default TrackingUsersList;
