import {
  Box,
  Button,
  Container,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import Breadcrumbs from "../../components/Breadcrumbs";
import GuardedComponent from "../../components/GuardedComponent";
import { Add as AddIcon } from "@material-ui/icons";
import AppDialog from "../../components/AppDialog";
import useToggle from "../../hooks/useToggle";
import { useEffect, useState } from "react";
import NotificationForm from "../../components/notification/NotificationForm";
import NotificationList from "../../components/notification/NotificationList";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getNotificationsAll,
  getNotificationsRules,
  updateNotificationActivationMutation,
} from "../../http/notifications";
import AppCircularProgress from "../../components/AppCircularProgress";
import useNotificationRule from "../../hooks/useNotificationRule";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/componentHelpers/trackings";

function HubnupNotifications() {
  const { isDisplayed: isFormModal, toggleAction: toggleFormModal } =
    useToggle();

  // data for the clicked request (click on title)
  const [clickedNotification, setClickedNotification] = useState({});


  const itemsPerPage = 9;
  const [page, setPage] = useState(1);
  const [notifications, setNotifications] = useState([]);
  const [filters, setFilters] = useState({});
  const [hasMoreNotifications, sethasMoreNotifications] = useState(true);

  const { isFetching, data } = useQuery(
    ["getNotificationAll", page, filters],
    () => getNotificationsAll(page, filters),
    { keepPreviousData: true },
  );

  useEffect(() => {
    if (data) {
      if (page === 1) {
        setNotifications(data["hydra:member"]);
      } else {
        setNotifications((prevState) => prevState.concat(data["hydra:member"]));
      }
      sethasMoreNotifications(page * itemsPerPage < data["hydra:totalItems"]);
    }
  }, [data, page]);

  const onFilter = (filters) => {
    setFilters(filters);
    setPage(1);
  };

  const track = useTrackingContext()

  const handleCloseModal = () => {
    setClickedNotification({});
    toggleFormModal();
  };

  const queryClient = useQueryClient();

  const notificationRulesQuery = useQuery({
    queryKey: ["notifications_rules"],
    queryFn: () =>
      getNotificationsRules().then((resp) => resp.data["hydra:member"]),
  });

  const toggleNotificationRuleActivationMutation = useMutation({
    mutationKey: ["notifications_rules"],
    mutationFn: ([id, value]) =>
      updateNotificationActivationMutation(id, value).then((resp) => resp.data),
  });

  const handleNotificationRuleActivations = async (rule) => {
    track.click(ACTION_CLICK.NOTY_TOGGLE_RULE)
    toggleNotificationRuleActivationMutation.mutate([
      rule.id,
      !rule.isActivate,
    ]);
    queryClient.setQueryData(["notifications_rules"], (rules) => {
      const index = rules.findIndex((search) => search.id === rule.id);
      if (index >= 0) rules[index].isActivate = !rules[index].isActivate;

      return [...rules];
    });
  };

  const notificationIsActivate = useNotificationRule("activate_notifications");

  return (
    <Container maxWidth={false}>
      <Breadcrumbs />
      <GuardedComponent componentName="notifications_page">
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <Box sx={{ mt: 3, mb: 3 }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={toggleFormModal}
              disabled={!notificationIsActivate}
            >
              Créer une notification personalisée
            </Button>
          </Box>
        </Box>

        <Box>
          {notificationRulesQuery.data?.map((rule) => (
            <FormControlLabel
              key={rule.id}
              control={
                <Checkbox
                  checked={rule.isActivate}
                  onChange={() => handleNotificationRuleActivations(rule)}
                />
              }
              label={rule.label}
            />
          ))}
        </Box>
      </GuardedComponent>

      {/* Modal: Add New request */}
      <AppDialog
        open={isFormModal}
        onClose={handleCloseModal}
        title="Notification personalisée"
        maxWidth={"md"}
      >
        {/* Custom Notification Form */}
        <NotificationForm
          toggleFormModal={handleCloseModal}
          clickedNotification={clickedNotification}
        />
      </AppDialog>

      <Box sx={{ mt: 3 }}>
        {/* Custom Notification List */}
        <NotificationList
          notifications={notifications}
          isFetching={isFetching}
          setClickedNotification={setClickedNotification}
          toggleFormModal={toggleFormModal}
        />
      </Box>

      {isFetching && <AppCircularProgress />}

      {/* when click on next(arrow) in DataGrid : there are no more notifications */}
      {!hasMoreNotifications && (
        <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
          <Typography variant="body1" color="text.disabled">
            Il n'y a plus d'actualité à afficher.
          </Typography>
        </Box>
      )}
    </Container>
  );
}

export default HubnupNotifications;
