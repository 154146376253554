import { LoadingButton } from "@material-ui/lab";
import React, { useState } from "react";
import { Box, Button, ButtonGroup, Card, Container, Stack } from "@material-ui/core";
import { Download as DownloadIcon, Search as SearchIcon } from "@material-ui/icons";
import { useMutation } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import GuardedComponent from "../../components/GuardedComponent";
import SalesRealEstateSummary from "../../components/sales/SalesRealEstateSummary";
import SalesRealEstateToolbar from "../../components/sales/SalesRealEstateToolbar";
import AgentSalesRealEstateList from "../../components/sales/AgentSalesRealEstateList";
import FilterPanel from "../../components/FilterPanel";
import { getExportExcelRealEstates } from "../../http/salesRealEstate";
import ExerciceFilter from "../../components/form/ExerciceFilter";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/componentHelpers/trackings";

const AgentSalesRealEstates = () => {
  const history = useHistory();
  const { agentId } = useParams();
  const actualYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(actualYear);
  const [filters, setFilters] = useState({ exercice: selectedYear });
  const [sortModel, setSortModel] = useState([{ field: "lastAction", sort: "desc" }]);

  const track = useTrackingContext()
  const onFilter = (filters) => {
    const data = {
      ...filters,
      exercice: selectedYear,
    };
    setFilters(data);
  };

  const onSubmit = () => {
    track.click(ACTION_CLICK.SET_FILTER)
    onFilter(filters);
  };

  const handleExportExcel = () => {
    track.click(ACTION_CLICK.EXPORT_MY_SALES_IMMO)
    mutation.mutate({
      filters,
      order: sortModel,
      agentId: agentId,
    });
  };

  const mutation = useMutation(getExportExcelRealEstates);

  const goBack = () => {
    history.goBack();
  };

  return (
    <Container maxWidth={false}>
      <Breadcrumbs/>

      <GuardedComponent componentName="sales_real_estate_sum_sales">
        <Box my={2}>
          <SalesRealEstateSummary fromRealEstate agentId={agentId}/>
        </Box>
      </GuardedComponent>

      <Box sx={{ mt: 3, mb: 3 }}>
        <GuardedComponent componentName="sales_real_estate_export">
          <LoadingButton
            loading={mutation.isLoading}
            color="primary"
            variant="contained"
            startIcon={<DownloadIcon/>}
            onClick={handleExportExcel}
          >
            Exporter
          </LoadingButton>
        </GuardedComponent>
        <ExerciceFilter selectedYear={selectedYear} setSelectedYear={setSelectedYear} actualYear={actualYear}
                        onSubmit={onSubmit}/>
      </Box>

      <FilterPanel sx={{ mt: 2 }}>
        <SalesRealEstateToolbar onFilter={onFilter} exercice={selectedYear} fromAgent/>
      </FilterPanel>

      <Card sx={{ mt: 2 }}>
        <AgentSalesRealEstateList
          filters={filters}
          sortModel={sortModel}
          setSortModel={setSortModel}
          agentId={agentId}
        />
      </Card>

      {agentId && (
        <Stack
          direction={{ xs: "column-reverse", sm: "row" }}
          spacing={2}
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button variant="outlined" onClick={goBack}>
            Retour
          </Button>
        </Stack>
      )}
    </Container>
  );
};

export default AgentSalesRealEstates;
