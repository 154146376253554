import dayjs from "dayjs";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography,
} from "@material-ui/core";
import { Refresh as RefreshIcon, Search as SearchIcon } from "@material-ui/icons";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toBigNumbers, toPercents } from "../../utils/formatters";
import { MobileDateRangePicker } from "@material-ui/lab";
import AutocompleteAgentsField from "../AutocompleteAgentsField";
import AutocompleteClientsField from "../AutocompleteClientsField";
import ValueLabelComponent from "../ValueLabelComponent";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/componentHelpers/trackings";

const SalesInvestmentToolbar = ({ onFilter, fromAgent, exercice }) => {
  const [agent, setAgent] = useState("");
  const [client, setClient] = useState("");
  const [resetFields, setResetFields] = useState(false);
  const defaultValues = {
    product: "",
    provider: "",
    amount: [0, 100000000],
    fees: [0, 1],
    operation: "",
    status: "",
    subscriptionDate: [null, null],
    exercice: exercice,
  };

  const schema = yup.object().shape({
    product: yup.string(),
    provider: yup.string(),
    amount: yup.array().of(yup.number()),
    fees: yup.array().of(yup.number()),
    operation: yup.string().oneOf(["", "Souscription", "Versement"]),
    status: yup.string().oneOf(["", "Validé", "Encours", "Terminé", "Annulé"]),
    subscriptionDate: yup.array().of(yup.date().nullable()),
  });

  const track = useTrackingContext()

  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const formatAmount = (value) => `${toBigNumbers(value)} €`;
  const formatPercent = (value) => `${toPercents(value)}`;

  const onSubmit = async (data) => {
    const filters = {
      ...data,
      agent: agent,
      client: client,
      exercice: exercice,
    };

    onFilter(filters);
    track.click(ACTION_CLICK.SET_FILTER)
  };

  const onReset = () => {
    setAgent("");
    setClient("");
    setResetFields(!resetFields);
    reset();
    onFilter({
      ...defaultValues,
      agent: "",
      client: "",
    });

    track.click(ACTION_CLICK.RESET_FILTER)
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        {!fromAgent && (
          <Grid item xs={6} lg={4}>
            <AutocompleteAgentsField setAgent={setAgent} reset={resetFields} />
          </Grid>
        )}
        <Grid item xs={6} lg={4}>
          <AutocompleteClientsField setClient={setClient} reset={resetFields} />
        </Grid>
        <Grid item xs={6} lg={4}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="amount-label" shrink={true}>
              Montant
            </InputLabel>
            <Controller
              name="amount"
              control={control}
              onChange={(event, newValue) => setValue("amount", newValue)}
              render={({ field }) => (
                <Slider
                  sx={{ mt: 4 }}
                  min={0}
                  max={100000}
                  step={1000}
                  valueLabelFormat={formatAmount}
                  components={{
                    ValueLabel: ValueLabelComponent,
                  }}
                  aria-labelledby="amount-label"
                  valueLabelDisplay="auto"
                  {...field}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} lg={4}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="status-label" shrink={true}>
              État
            </InputLabel>
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <Select {...field} labelId="status-label">
                  <MenuItem value="">
                    <em>Tous</em>
                  </MenuItem>
                  <MenuItem value="Validé">Validé</MenuItem>
                  <MenuItem value="Encours">Encours</MenuItem>
                  <MenuItem value="Terminé">Terminé</MenuItem>
                  <MenuItem value="Annulé">Annulé</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} lg={4}>
          <Controller
            name="product"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Produit"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="fees-label" shrink={true}>
              Frais
            </InputLabel>
            <Controller
              name="fees"
              control={control}
              onChange={(event, newValue) => setValue("fees", newValue)}
              render={({ field }) => (
                <Slider
                  sx={{ mt: 4 }}
                  min={0}
                  max={0.05}
                  step={0.0001}
                  valueLabelFormat={formatPercent}
                  components={{
                    ValueLabel: ValueLabelComponent,
                  }}
                  aria-labelledby="fees-label"
                  valueLabelDisplay="auto"
                  {...field}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} lg={4}>
          <Controller
            name="subscriptionDate"
            control={control}
            onChange={(event, newValue) => setValue("subscriptionDate", newValue)}
            render={({ field }) => (
              <MobileDateRangePicker
                {...field}
                startText="Souscription fournisseur du"
                endText="au"
                inputFormat="DD/MM/YYYY"
                allowSameDateSelection
                okText="Valider"
                cancelText="Annuler"
                toolbarTitle="Choisissez une plage de date"
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField
                      {...startProps}
                      fullWidth
                      variant="filled"
                      InputLabelProps={{ shrink: true }}
                      helperText=""
                    />
                    <Typography sx={{ mx: 2 }} variant="body1">
                      {" "}
                      -{" "}
                    </Typography>
                    <TextField
                      {...endProps}
                      fullWidth
                      variant="filled"
                      InputLabelProps={{ shrink: true }}
                      helperText=""
                    />
                  </>
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <Controller
            name="provider"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Fournisseur"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <FormControl fullWidth variant="filled">
            <InputLabel id="operation-label" shrink={true}>
              Opération
            </InputLabel>
            <Controller
              name="operation"
              control={control}
              render={({ field }) => (
                <Select {...field} labelId="operation-label">
                  <MenuItem value="">
                    <em>Tous</em>
                  </MenuItem>
                  <MenuItem value="Souscription">Souscription</MenuItem>
                  <MenuItem value="Versement">Versement</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        {!fromAgent && <Grid item display={{ xs: "none", lg: "block" }} lg={8} />}
        <Grid item xs={6} lg={4} container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Button fullWidth variant="contained" startIcon={<SearchIcon />} type="submit">
              Filtrer
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth variant="outlined" startIcon={<RefreshIcon />} onClick={() => onReset()}>
              Réinitialiser
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

SalesInvestmentToolbar.defaultProps = {
  fromAgent: false,
};

SalesInvestmentToolbar.propTypes = {
  onFilter: PropTypes.func,
  fromAgent: PropTypes.bool,
  exercice: PropTypes.string,
};

export default SalesInvestmentToolbar;
