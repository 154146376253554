import { LoadingButton } from "@material-ui/lab";
import { connect } from "react-redux";
import React, { useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@material-ui/core";
import { Download as DownloadIcon, Search as SearchIcon, HelpOutline } from "@material-ui/icons";
import { useMutation } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import FilterPanel from "../../components/FilterPanel";
import Breadcrumbs from "../../components/Breadcrumbs";
import GuardedComponent from "../../components/GuardedComponent";
import TrainingTeamListAll from "../../components/training/TrainingTeamListAll";
import TrainingTeamListCount from "../../components/training/TrainingTeamListCount";
import TrainingTeamToolbar from "../../components/training/TrainingTeamToolbar";
import { getExportEventsDetails, getExportEventsTeam } from "../../http/trainings";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/componentHelpers/trackings";

const TrainingTeam = ({ agentId }) => {
  const actualYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(actualYear);
  const [filters, setFilters] = useState({ exercice: selectedYear });
  const rangeYears = 5;
  const years = Array(rangeYears)
    .fill("")
    .map((v, idx) => actualYear + idx - (rangeYears - 1));

  const track = useTrackingContext()

  const onFilter = (filters) => {
    const data = {
      ...filters,
      exercice: selectedYear,
    };
    setFilters(data);
  };

  const onSubmit = () => {
    track.click(ACTION_CLICK.SET_FILTER)
    onFilter(filters);
  };

  const handleExportExcel = () => {
    track.click(ACTION_CLICK.EXPORT_TEAM_TRAINING)

    mutation.mutate({
      filters,
      agentId,
    });
  };

  const handleExportExcelDetails = () => {
    track.click(ACTION_CLICK.EXPORT_TEAM_DETAILS)

    mutationDetails.mutate({
      filters,
      agentId,
    });
  };

  const mutation = useMutation(getExportEventsTeam);
  const mutationDetails = useMutation(getExportEventsDetails);

  return (
    <Container maxWidth={false}>
      <Breadcrumbs />
      <Box>
        <GuardedComponent componentName="training_team_export">
          <LoadingButton
            loading={mutation.isLoading}
            color="primary"
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={handleExportExcel}
          >
            Export formation équipe
          </LoadingButton>
          <LoadingButton
            loading={mutationDetails.isLoading}
            color="primary"
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={handleExportExcelDetails}
          >
            Export détail équipe
          </LoadingButton>
        </GuardedComponent>
        <ButtonGroup variant="outlined" aria-label="outlined primary button group" sx={{ marginLeft: "10px" }}>
          {years.map((year) => (
            <Button
              variant={selectedYear === year ? "contained" : "outlined"}
              key={year}
              onClick={() => setSelectedYear(year)}
            >
              {year}
            </Button>
          ))}
          <Button variant={!selectedYear ? "contained" : "outlined"} onClick={() => setSelectedYear()}>
            Tous
          </Button>
        </ButtonGroup>
        <Button variant="contained" startIcon={<SearchIcon />} onClick={onSubmit} sx={{ marginLeft: "10px" }}>
          Filtrer
        </Button>
      </Box>

      <FilterPanel sx={{ mt: 2 }}>
        <TrainingTeamToolbar onFilter={onFilter} />
      </FilterPanel>

      <Grid item xs={12} sx={{ mt: 2 }}>
        <Card>
          <Divider />
          <CardContent
            sx={{
              p: 1,
            }}
          >
            Les formations sont les ateliers dispensés par le Trainer’s Club et sont comptabilisées dans vos 10
            formations recommandées. Les évènements sont les trois évènements principaux (Convention, Journées
            Partenaires et Move’UP), ainsi que les Ateliers Partenaires et les Cercles. Un exercice s'étend du 1er
            Novembre N au 31 octobre N+1
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sx={{ mt: 2 }}>
        <Card>
          <CardHeader
            title={<Typography color="white">{agentId ? "Formation Equipe" : "Formation Equipe"}</Typography>}
            style={{ backgroundColor: "#00A19A" }}
          />
          <Divider />
          <CardContent
            sx={{
              p: 1,
            }}
          >
            <TrainingTeamListCount filters={filters} agentId={agentId} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sx={{ mt: 2 }}>
        <Card>
          <CardHeader
            title={<Typography color="white">{agentId ? "Détail Equipe" : "Détail Equipe"}</Typography>}
            style={{ backgroundColor: "#00A19A" }}
          />
          <Divider />
          <CardContent
            sx={{
              p: 1,
            }}
          >
            <TrainingTeamListAll filters={filters} agentId={agentId} />
          </CardContent>
        </Card>
      </Grid>
      <Stack
        direction={{ xs: "column-reverse", sm: "row" }}
        spacing={2}
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "flex-end",
        }}
      ></Stack>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  agentId: state.auth.cgpId,
});

export default connect(mapStateToProps)(TrainingTeam);
