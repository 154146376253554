import { Stack } from "@material-ui/core";
import React from "react";
import { useQuery } from "react-query";

import { exportDocuments, retrieveDocuments } from "../../../http/tracking";
import DocumentsChart from "../charts/DocumentsChart";

const TrackingDocuments = () => {
  const documentsQuery = useQuery({
    queryKey: ["tracking", "documents"],
    queryFn: () => retrieveDocuments(),
  });

  return (
    <Stack>
      {documentsQuery.isSuccess && (
        <DocumentsChart
          items={documentsQuery.data}
          onExport={() => exportDocuments()}
        />
      )}
    </Stack>
  );
};

export default TrackingDocuments;
