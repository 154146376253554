import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Button, CardContent, Divider, Grid, Stack, TextField, Typography } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { DatePicker, LoadingButton } from "@material-ui/lab";
import { Check as CheckIcon, FolderOpen } from "@material-ui/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import { postProspects } from "../../http/prospects";
import dayjs from "dayjs";
import { dateToString, dateToStringYmd } from "../../utils/formatters";
import checkAccessStore from "../../utils/checkAccessStore";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/componentHelpers/trackings";

const ProspectsForm = ({ onClose }) => {
  const [ricFile, setRicFile] = useState(null);
  const [derFile, setDerFile] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const schema = yup.object().shape({
    lastName: yup.string().required(),
    firstName: yup.string().required(),
    birthday: yup.date().nullable().required(),
  });
  const filesRequired = checkAccessStore("prospects_required_documents");

  const { control, handleSubmit } = useForm({
    defaultValues: {
      lastName: "",
      firstName: "",
      birthday: null,
    },
    resolver: yupResolver(schema),
  });

  const track = useTrackingContext()

  const onSubmit = async (data) => {
    let formData = new FormData();

    let now = dayjs();
    now = dateToStringYmd(now);
    const fullName = `${data.lastName}-${data.firstName}`;
    if (ricFile) {
      const fileExt = ricFile.name.split(".").pop();
      formData.append("ricFile", ricFile, `${now}_RIC_${fullName}.${fileExt}`);
    }
    if (derFile) {
      const fileExt = ricFile.name.split(".").pop();
      formData.append("derFile", derFile, `${now}_DER_${fullName}.${fileExt}`);
    }
    formData.append("lastName", data.lastName);
    formData.append("firstName", data.firstName);
    formData.append("birthday", dateToString(data.birthday));

    track.click(ACTION_CLICK.CREATE_PROSPECT)

    mutation.mutate(formData);
  };

  const mutation = useMutation(postProspects, {
    onSuccess: () => {
      queryClient.invalidateQueries("getProspects");
      enqueueSnackbar("Le prospect a bien été créé.", { variant: "success" });
      onClose();
    },
  });

  const handleChangeFileRic = (event) => {
    setRicFile(event.target.files[0]);
  };

  const handleChangeFileDer = (event) => {
    setDerFile(event.target.files[0]);
  };

  const renderFile = (file) => {
    let previewFile = null;
    if (file) {
      previewFile = file.name;
    }

    if (previewFile) {
      return (
        <Typography variant="string" sx={{ marginLeft: "10px" }}>
          {previewFile}
        </Typography>
      );
    }

    return null;
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Controller
              name="lastName"
              control={control}
              shouldUnregister
              render={({ field, formState: { errors } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label="Nom"
                  margin="normal"
                  error={Boolean(errors.lastName?.message)}
                  helperText={errors.lastName?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="firstName"
              control={control}
              shouldUnregister
              render={({ field, formState: { errors } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label="Prénom"
                  margin="normal"
                  error={Boolean(errors.firstName?.message)}
                  helperText={errors.firstName?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "8px" }}>
            <Controller
              name="birthday"
              control={control}
              render={({ field, formState: { errors } }) => (
                <DatePicker
                  {...field}
                  label="Date de naissance"
                  inputFormat="DD/MM/YYYY"
                  shouldUnregister
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      fullWidth
                      required
                      error={Boolean(errors.birthday?.message)}
                      helperText={errors.birthday?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sx={{ marginY: "16px" }}>
            <Button variant="contained" component="label" startIcon={<FolderOpen />}>
              Télécharger un RIC
              <input
                type="file"
                hidden
                accept=".pdf, .jpg, .png, .jpeg, .doc, .docx, .odt"
                onChange={handleChangeFileRic}
              />
            </Button>
            {renderFile(ricFile)}
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" component="label" startIcon={<FolderOpen />}>
              Télécharger un DER
              <input
                type="file"
                hidden
                accept=".pdf, .jpg, .png, .jpeg, .doc, .docx, .odt"
                onChange={handleChangeFileDer}
              />
            </Button>
            {renderFile(derFile)}
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Stack
        direction={{ xs: "column-reverse", sm: "row" }}
        spacing={2}
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button variant="outlined" onClick={onClose}>
          Annuler
        </Button>
        <LoadingButton
          loading={mutation.isLoading}
          variant="contained"
          type="submit"
          startIcon={<CheckIcon />}
          disabled={filesRequired && !ricFile && !derFile}
        >
          Valider
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default ProspectsForm;
