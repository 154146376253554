import React, { useMemo } from "react";
import { Box, Paper } from "@material-ui/core";
import { Chart } from "react-google-charts";
import {
  getLabel,
  isUntrackedPage,
} from "../../../utils/componentHelpers/trackings";
import { LoadingButton } from "@material-ui/lab";
import { Download } from "@material-ui/icons";
import useAction from "../../../hooks/useAction";

const TopPagesChart = ({ items, onExport }) => {
  const options = {
    title: "Pages les plus consultées les 10 derniers jours",
    chartArea: { width: "60%" },
    isStacked: true,
    hAxis: {
      title: "Nombre de clics",
      minValue: 0,
    },
    vAxis: {
      title: "Pages",
    },
  };

  const action = useAction();

  const data = useMemo(() => {
    if (!items) return [];

    return items
      .filter((item) => !isUntrackedPage(item.path, { open: true }))
      .slice(0, 10)
      .map((item) => ({ ...item, path: getLabel(item.path) }));
  }, [items]);

  if (!data.length) return null;

  return (
    <Paper elevation={2} sx={{ position: "relative" }}>
      <Chart
        chartType="BarChart"
        width="100%"
        height="600px"
        options={options}
        data={[
          ["Pages", "Nombre de consultations"],
          ...data.map((item) => [item.path, item.value]),
        ]}
      />
      {onExport && (
        <Box sx={{ position: "absolute", top: "2%", right: "2%" }}>
          <LoadingButton
            loading={action.pending}
            startIcon={<Download />}
            variant="contained"
            onClick={action.handle(() => onExport())}
          >
            Exporter
          </LoadingButton>
        </Box>
      )}
    </Paper>
  );
};

export default TopPagesChart;
