import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@material-ui/lab";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Alert, Button, DialogActions, DialogContent, TextField, makeStyles, Divider } from "@material-ui/core";
import { FolderOpen } from "@material-ui/icons";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import { postPicture } from "../../http/libraryPictures";
import AppDialog from "../AppDialog";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/componentHelpers/trackings";

const AddPicture = ({ open, title, onClose, picture, mediaObject }) => {
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const queryClient = useQueryClient();
  const schema = yup.object().shape({
    title: yup.string().required("Ce champ est obligatoire."),
  });

  const track = useTrackingContext()

  const {
    handleSubmit,
    formState: { errors },
    control,
    unregister,
    setValue,
  } = useForm({
    defaultValues: {
      title: picture?.title || "",
    },
    resolver: yupResolver(schema),
  });

  const handleClose = (result) => {
    setValue("title", "");
    unregister("title");
    setFile(null);
    onClose(result);
  };

  const onSubmit = async ({ title }) => {
    let formData = null;

    if (file) {
      formData = new FormData();
      formData.append("file", file);
    }

    track.click(ACTION_CLICK.ADD_PICTURE)

    mutation.mutate({ formData, title, picture, mediaObject });
  };

  const mutation = useMutation(postPicture, {
    onSuccess: (result) => {
      queryClient.invalidateQueries("getLibraryPictures");
      handleClose(result);
    },
  });

  const handleChangeFile = (event) => {
    setFile(event.target.files[0]);
  };

  const renderFile = () => {
    let previewFile = null;
    if (mediaObject) {
      previewFile = process.env.REACT_APP_API_URL + mediaObject.contentUrl;
    }
    if (file) {
      previewFile = URL.createObjectURL(file);
    }

    if (previewFile) {
      return <img className={classes.previewPicture} alt="preview" src={previewFile} />;
    }

    return null;
  };

  return (
    <AppDialog open={open} onClose={handleClose} title={title}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          {mutation.isError ? <Alert severity="error">Une erreur est survenue : {mutation.error.message}</Alert> : null}

          <Controller
            name="title"
            control={control}
            variant="outlined"
            shouldUnregister="true"
            render={({ field }) => (
              <TextField
                {...field}
                error={Boolean(errors.title?.message)}
                helperText={errors.title?.message}
                fullWidth
                label="Titre"
                variant="outlined"
                margin="normal"
                autoComplete="title"
                type="text"
              />
            )}
          />
          <Button variant="contained" component="label" startIcon={<FolderOpen />}>
            Télécharger un fichier
            <input type="file" hidden onChange={handleChangeFile} />
          </Button>
          {renderFile()}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Annuler
          </Button>
          <LoadingButton
            loading={mutation.isLoading}
            type="submit"
            variant="contained"
            disabled={!file && !mediaObject}
          >
            Enregistrer
          </LoadingButton>
        </DialogActions>
      </form>
    </AppDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  previewPicture: {
    width: "100%",
    maxHeight: "500px",
    marginTop: "1rem",
    objectFit: "contain",
  },
}));

AddPicture.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  picture: PropTypes.object,
  mediaObject: PropTypes.object,
};

export default AddPicture;
