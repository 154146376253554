import React from "react";
import { useQuery } from "react-query";
import { Stack } from "@material-ui/core";

import {
  exportUserDocuments,
  retrieveUserDocuments,
} from "../../../http/tracking";
import DocumentsChart from "../charts/DocumentsChart";

const TrackingUserDocuments = ({ user }) => {
  const documentsQuery = useQuery({
    queryKey: ["tracking", "documents", user.id],
    queryFn: () => retrieveUserDocuments(user.id),
  });

  return (
    <Stack>
      {documentsQuery.isSuccess && (
        <DocumentsChart
          items={documentsQuery.data}
          onExport={() => exportUserDocuments(user.id)}
        />
      )}
    </Stack>
  );
};

export default TrackingUserDocuments;
