import { LoadingButton } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React from "react";
import { Alert, Button, DialogActions, DialogContent, DialogContentText, Divider } from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { deleteUser } from "../../http/user";
import AppDialog from "../AppDialog";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/componentHelpers/trackings";

const UserDeleteModal = ({ open, onClose, user, goBack }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const queryClient = useQueryClient();
  const track = useTrackingContext();

  const onConfirm = () => {
    track.click(ACTION_CLICK.ADMIN_DELETE_USER)
    mutation.mutate(user["@id"]);
  };

  const mutation = useMutation(deleteUser, {
    onSuccess: () => {
      if (goBack) {
        history.push(goBack);
      }

      queryClient.invalidateQueries("getUsersAll");
      enqueueSnackbar("L'utilisateur a bien été supprimé.", { variant: "success" });
      onClose();
    },
  });

  return (
    <AppDialog open={open} onClose={onClose} title="Suppression d'un utilisateur">
      <DialogContent>
        {mutation.isError ? <Alert severity="error">Une erreur est survenue : {mutation.error.message}</Alert> : null}

        {user && (
          <DialogContentText id="reset-password-dialog-description">
            Confirmez-vous la suppression de l'utilisateur {user.firstName} {user.lastName}
          </DialogContentText>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Annuler
        </Button>
        <LoadingButton
          loading={mutation.isLoading}
          variant="contained"
          color="hubnup"
          onClick={onConfirm}
          startIcon={<DeleteIcon />}
        >
          Supprimer
        </LoadingButton>
      </DialogActions>
    </AppDialog>
  );
};

UserDeleteModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  user: PropTypes.object,
  goBack: PropTypes.func,
};

export default UserDeleteModal;
