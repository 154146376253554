import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, CardHeader, Container, Divider } from "@material-ui/core";
import DocumentsList from "../components/documentation/DocumentsList";
import DocumentsToolbar from "../components/documentation/DocumentsToolbar";
import GuardedComponent from "../components/GuardedComponent";
import { Add as AddIcon, ContentCopy as CopyIcon } from "@material-ui/icons";
import CreateFolderModal from "../components/documentation/CreateFolderModal";
import CreateFileModal from "../components/documentation/CreateFileModal";
import { useQuery } from "react-query";
import DocumentsBreadcrumbs from "../components/documentation/DocumentsBreadcrumbs";
import DocumentsListFiltered from "../components/documentation/DocumentsListFiltered";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { getCurrentUser } from "../http/user";
import { useTrackingContext } from "../contexts/TrackingContext";
import { ACTION_CLICK } from "../utils/componentHelpers/trackings";

const Documentation = ({ componentName, type, foldersQuery }) => {
  const [filters, setFilters] = useState({});
  const [sort, setSort] = useState(true);
  const [firstSearch, setFirstSearch] = useState(true);
  const [currentFolder, setCurrentFolder] = useState({});
  const [breadcrumbs, setBreadCrumbs] = useState([]);
  const [documentsToShow, setDocumentsToShow] = useState([]);
  const [filteredAllDocumentsToShow, setFilteredAllDocumentsToShow] = useState([]);
  const [openCreateFolderModal, setOpenCreateFolderModal] = useState(false);
  const [openCreateFileModal, setOpenCreateFileModal] = useState(false);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const track = useTrackingContext()

  const { data: user } = useQuery(["getCurrentUser"], () => {
    return getCurrentUser();
  });

  const { isFetching, data: documents } = useQuery(["getFolders"], () => {
    return foldersQuery();
  });

  useEffect(() => {
    if (!isFetching && documents) {
      if (id && firstSearch) {
        setBreadCrumbs([documents]);
        const doc = searchChildId(documents, id, breadcrumbs);
        setFirstSearch(false);
      } else if (firstSearch) {
        const { childrenFolders, mediaObjects, ...restFolder } = documents;
        setCurrentFolder(documents);
        setBreadCrumbs([documents]);
        setDocumentsToShow(childrenFolders.sort(sortFolders).concat(mediaObjects.sort(sortFiles)));
        setFirstSearch(false);
      } else {
        const updatedBreadcrumbs = [];
        breadcrumbs.forEach((breadcrumb) => {
          let document = hydrateBreadcrumbAndCurrentFolderAfterUpdate(breadcrumb, documents);
          const { childrenFoldersDocument, mediaObjectsDocument, ...restFolderDocument } = document;
          updatedBreadcrumbs.push(document);
        });
        setBreadCrumbs(updatedBreadcrumbs);
        const currentFolder = updatedBreadcrumbs[updatedBreadcrumbs.length - 1];
        setCurrentFolder(currentFolder);
        setDocumentsToShow(
          currentFolder.childrenFolders.sort(sortFolders).concat(currentFolder.mediaObjects.sort(sortFiles))
        );
      }
    }
  }, [isFetching, documents, firstSearch, id]);

  useEffect(() => {
    if (currentFolder.id) {
      if (filters.title) {
        const filterTitle = filters.title.toLowerCase();
        const filteredDocuments = [];
        recursiveFilteredDocuments(
          currentFolder.childrenFolders.sort(sortFolders).concat(currentFolder.mediaObjects.sort(sortFiles)),
          filteredDocuments,
          filterTitle,
          breadcrumbs
        );
        setDocumentsToShow(filteredDocuments);

        const filteredAllDocuments = [];
        recursiveFilteredAllDocuments([documents], filteredAllDocuments, [], filterTitle);
        setFilteredAllDocumentsToShow(filteredAllDocuments);
      } else {
        setFilteredAllDocumentsToShow([]);
        const currentFolder = breadcrumbs[breadcrumbs.length - 1];
        if (breadcrumbs.length === 1 && !id) {
          setDocumentsToShow(
            documents.childrenFolders.sort(sortFolders).concat(documents.mediaObjects.sort(sortFiles))
          );
        } else {
          setDocumentsToShow(
            currentFolder.childrenFolders.sort(sortFolders).concat(currentFolder.mediaObjects.sort(sortFiles))
          );
        }
      }
    }
  }, [filters]);

  const recursiveFilteredDocuments = (documents, filteredDocuments, filterTitle, breadcrumbs) => {
    documents.forEach((document) => {
      if (document.filePath) {
        if (document.displayName.toLowerCase().includes(filterTitle)) {
          const filteredDocument = { ...document };
          filteredDocument.breadcrumbs = breadcrumbs;
          filteredDocument.breadcrumbsString = handleGetSecondaryTextDocument(filteredDocument);
          filteredDocuments.push(filteredDocument);
        }
      } else {
        if (document.name.toLowerCase().includes(filterTitle)) {
          const filteredDocument = { ...document };
          filteredDocument.breadcrumbs = breadcrumbs;
          filteredDocument.breadcrumbsString = handleGetSecondaryTextDocument(filteredDocument);
          filteredDocuments.push(filteredDocument);
        }
        const newBreadcrumbs = [...breadcrumbs];
        newBreadcrumbs.push(document);
        recursiveFilteredDocuments(
          document.childrenFolders.concat(document.mediaObjects),
          filteredDocuments,
          filterTitle,
          newBreadcrumbs
        );
      }
    });
  };

  const recursiveFilteredAllDocuments = (documents, filteredDocuments, breadcrumbs, filterTitle) => {
    documents.forEach((document) => {
      if (document.id !== currentFolder.id) {
        if (document.filePath) {
          if (document.displayName.toLowerCase().includes(filterTitle)) {
            const filteredDocument = { ...document };
            filteredDocument.breadcrumbs = breadcrumbs;
            filteredDocument.breadcrumbsString = handleGetSecondaryTextDocument(filteredDocument);
            filteredDocuments.push(filteredDocument);
          }
        } else {
          if (document.name.toLowerCase().includes(filterTitle)) {
            const filteredDocument = { ...document };
            filteredDocument.breadcrumbs = breadcrumbs;
            filteredDocument.breadcrumbsString = handleGetSecondaryTextDocument(filteredDocument);
            filteredDocuments.push(filteredDocument);
          }
          const newBreadcrumbs = [...breadcrumbs];
          newBreadcrumbs.push(document);
          recursiveFilteredAllDocuments(
            document.childrenFolders.concat(document.mediaObjects),
            filteredDocuments,
            newBreadcrumbs,
            filterTitle
          );
        }
      }
    });
  };

  const searchChildId = (currentDocuments, id, breadcrumbs) => {
    if (currentDocuments.id === id) {
      setDocumentsToShow(
        currentDocuments.childrenFolders.sort(sortFolders).concat(currentDocuments.mediaObjects.sort(sortFiles))
      );
      setCurrentFolder(currentDocuments);
      setBreadCrumbs(getBreadCrumbs(documents, id));
    } else {
      const { childrenFolders, mediaObjects, ...restFolder } = currentDocuments;
      let childrendocument = false;
      for (let childrenFolder of childrenFolders) {
        const found = searchChildId(childrenFolder, id);
        if (found) {
          childrendocument = found;
          return true;
        }
      }
    }
    return false;
  };

  const getBreadCrumbs = (currentDoc, id) => {
    if (currentDoc.id === id) {
      return [currentDoc];
    }
    if (!currentDoc.childrenFolders) {
      return null;
    } else {
      const { childrenFolders, mediaObjects, ...restFolder } = currentDoc;
      for (var i = 0; i < childrenFolders.length; i++) {
        const child = currentDoc.childrenFolders[i];
        const path = getBreadCrumbs(child, id);
        if (path) {
          return [currentDoc, ...path];
        }
      }
    }
  };

  const handleGetSecondaryTextDocument = (document) => {
    let breadcrumbsString = "";
    document.breadcrumbs.forEach((breadcrumb, index) => {
      breadcrumbsString += breadcrumb.name;
      if (index !== document.breadcrumbs.length - 1) {
        breadcrumbsString += " / ";
      }
    });

    return breadcrumbsString;
  };

  const hydrateBreadcrumbAndCurrentFolderAfterUpdate = (breadcrumb, document) => {
    const { childrenFolders, mediaObjects, ...restFolder } = document;

    if (breadcrumb.id === restFolder.id) {
      return document;
    } else {
      let childrendocument = false;
      for (let childrenFolder of childrenFolders) {
        const found = hydrateBreadcrumbAndCurrentFolderAfterUpdate(breadcrumb, childrenFolder);
        if (found) {
          childrendocument = found;
          break;
        }
      }

      return childrendocument;
    }
  };

  const handleSortChange = () => {
    const newDocumentsList = documentsToShow.reverse();
    setDocumentsToShow(newDocumentsList);
    setSort(!sort);
  };

  const onFilter = (filters) => {
    setFilters(filters);
  };

  const handleClickCreateFolderModal = () => {
    setOpenCreateFolderModal(true);
  };

  const handleClickCreateFileModal = () => {
    setOpenCreateFileModal(true);
  };

  const handleCloseCreateFolderModal = () => {
    setOpenCreateFolderModal(false);
  };

  const handleCloseCreateFileModal = () => {
    setOpenCreateFileModal(false);
  };

  const handleClickGenerateUrl = () => {
    track.click(ACTION_CLICK.DOCUMENT_COPY_LINK)

    const url = window.location.origin + "/library/" + type + "/" + currentFolder.id;
    navigator.clipboard.writeText(url);
    enqueueSnackbar("Lien copié ", { variant: "success" });
  };

  const handleNavigateToFolder = (folder) => {
    track.click(ACTION_CLICK.DOCUMENT_OPEN_FOLDER)
    const { childrenFolders, mediaObjects, ...newCurrentFolder } = folder;
    setDocumentsToShow(childrenFolders.sort(sortFolders).concat(mediaObjects.sort(sortFiles)));
    setCurrentFolder(folder);
    const newBreadcrumbs = breadcrumbs;
    newBreadcrumbs.push(folder);
    setBreadCrumbs(newBreadcrumbs);
  };

  const handleNavigateToFolderFiltered = (document) => {
    if (document.filePath) {
      const currentFolder = document.breadcrumbs[document.breadcrumbs.length - 1];
      setDocumentsToShow(
        currentFolder.childrenFolders.sort(sortFolders).concat(currentFolder.mediaObjects.sort(sortFiles))
      );
      setCurrentFolder(currentFolder);
      setBreadCrumbs(document.breadcrumbs);
    } else {
      const { childrenFolders, mediaObjects, breadcrumbs, ...newCurrentFolder } = document;
      setDocumentsToShow(childrenFolders.sort(sortFolders).concat(mediaObjects.sort(sortFiles)));
      setCurrentFolder(document);
      setBreadCrumbs(breadcrumbs);
    }
    setFilters({});
  };

  const handleUpdateBreadCrumbs = (folder) => {
    const foundAt = breadcrumbs.findIndex((breadcrumb) => breadcrumb.id === folder.id);
    const newBreadcrumbs = breadcrumbs.slice(0, foundAt + 1);

    if (foundAt === 0) {
      const { childrenFolders, mediaObjects, ...restFolder } = documents;
      setCurrentFolder(documents);
      setDocumentsToShow(childrenFolders.sort(sortFolders).concat(mediaObjects.sort(sortFiles)));
    } else {
      const document = breadcrumbs[foundAt];
      const { childrenFolders, mediaObjects, ...restFolder } = document;
      setCurrentFolder(document);
      setDocumentsToShow(childrenFolders.sort(sortFolders).concat(mediaObjects.sort(sortFiles)));
    }

    setBreadCrumbs(newBreadcrumbs);
  };

  const sortFolders = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  const sortFiles = (a, b) => {
    if (a.displayName < b.displayName) {
      return -1;
    }
    if (a.displayName > b.displayName) {
      return 1;
    }
    return 0;
  };

  return (
    <Container maxWidth={false}>
      <DocumentsBreadcrumbs breadcrumbs={breadcrumbs} handleUpdateBreadCrumbs={handleUpdateBreadCrumbs} />

      <GuardedComponent componentName={componentName}>
        <Box sx={{ mt: 3, mb: 3 }}>
          <Button variant="contained" startIcon={<AddIcon />} onClick={() => handleClickCreateFolderModal()}>
            Ajouter un dossier
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleClickCreateFileModal()}
            sx={{ marginLeft: "10px" }}
          >
            Ajouter un fichier
          </Button>
          <Button
            variant="contained"
            startIcon={<CopyIcon />}
            onClick={() => handleClickGenerateUrl()}
            sx={{ marginLeft: "10px" }}
          >
            Copier le lien
          </Button>
        </Box>
      </GuardedComponent>

      <Card>
        <CardContent>
          <DocumentsToolbar onFilter={onFilter} filters={filters} />
        </CardContent>
      </Card>

      <Box sx={{ mt: 2 }}>
        <Card>
          <DocumentsList
            documentsToShow={documentsToShow}
            handleNavigateToFolder={handleNavigateToFolder}
            handleSortChange={handleSortChange}
            loading={isFetching}
            sort={sort}
            componentName={componentName}
            type={type}
            breadcrumbs={breadcrumbs}
            handleUpdateBreadCrumbs={handleUpdateBreadCrumbs}
          />
        </Card>
      </Box>

      {filters.title && (
        <Box sx={{ mt: 2 }}>
          <Card>
            <CardHeader title="Recherche dans d'autres dossiers" />
            <Divider />
            <CardContent
              sx={{
                p: 1,
              }}
            >
              <DocumentsListFiltered
                documentsToShow={filteredAllDocumentsToShow}
                handleNavigateToFolderFiltered={handleNavigateToFolderFiltered}
              />
            </CardContent>
          </Card>
        </Box>
      )}

      <GuardedComponent componentName={componentName}>
        {openCreateFolderModal && (
          <CreateFolderModal
            open={openCreateFolderModal}
            onClose={handleCloseCreateFolderModal}
            title="Créer un nouveau dossier"
            queryToInvalidate="getFolders"
            type={type}
            person={user}
            currentFolder={currentFolder}
          />
        )}

        {openCreateFileModal && (
          <CreateFileModal
            open={openCreateFileModal}
            onClose={handleCloseCreateFileModal}
            title="Ajouter un fichier"
            queryToInvalidate="getFolders"
            type={type}
            person={user}
            currentFolder={currentFolder}
          />
        )}
      </GuardedComponent>
    </Container>
  );
};

export default Documentation;
