import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import { Refresh as RefreshIcon, Search as SearchIcon } from "@material-ui/icons";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AutocompleteAgentsField from "../AutocompleteAgentsField";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/componentHelpers/trackings";

const ProspectsToolbar = ({ onFilter, fromAgent }) => {
  const [agent, setAgent] = useState("");
  const [resetFields, setResetFields] = useState(false);
  const defaultValues = {
    lastName: "",
    firstName: "",
  };

  const schema = yup.object().shape({
    lastName: yup.string(),
    firstName: yup.string(),
  });

  const track = useTrackingContext()

  const { control, handleSubmit, reset } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const filters = {
      ...data,
      agent: agent,
    };

    track.click(ACTION_CLICK.SET_FILTER)
    onFilter(filters);
  };

  const onReset = () => {
    setAgent("");
    setResetFields(!resetFields);
    reset();

    track.click(ACTION_CLICK.RESET_FILTER)
    onFilter({
      ...defaultValues,
      agent: "",
    });
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        {!fromAgent && (
          <Grid item xs={6} lg={4}>
            <AutocompleteAgentsField setAgent={setAgent} reset={resetFields} />
          </Grid>
        )}
        <Grid item xs={6} lg={4}>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Nom"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Prénom"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        {!fromAgent && <Grid item display={{ xs: "none", lg: "block" }} lg={8} />}
        <Grid item xs={6} lg={4} container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Button fullWidth variant="contained" startIcon={<SearchIcon />} type="submit">
              Filtrer
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth variant="outlined" startIcon={<RefreshIcon />} onClick={() => onReset()}>
              Réinitialiser
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

ProspectsToolbar.defaultProps = {
  fromAgent: false,
};

ProspectsToolbar.propTypes = {
  onFilter: PropTypes.func,
  fromAgent: PropTypes.bool,
};

export default ProspectsToolbar;
