import React from "react";
import { useQuery } from "react-query";
import { Stack } from "@material-ui/core";

import {
  retrieveUserConnexionByDays,
  retrieveUserDeviceClick,
  retrieveUserUniqueConnectionByYear,
} from "../../../http/tracking";
import DevicesClickCharts from "../charts/DevicesClickCharts";
import UniqueConnectionIndicator from "../charts/UniqueConnectionIndicator";
import LastDaysConnectionsChart from "../charts/LastDaysConnectionsChart";

const TrackingUserConnection = ({ user }) => {
  const deviceQuery = useQuery({
    queryKey: ["retrieveUserDeviceClick", user.id],
    queryFn: () => retrieveUserDeviceClick(user.id),
  });

  const uniqueConnectionQuery = useQuery({
    queryKey: ["tracking", "unique-query", user.id],
    queryFn: () => retrieveUserUniqueConnectionByYear(user.id),
  });

  const connectionsQuery = useQuery({
    queryKey: ["tracking", "connections-by-days", user.id],
    queryFn: () => retrieveUserConnexionByDays(user.id),
  });

  return (
    <Stack gap="2rem">
      <UniqueConnectionIndicator connection={uniqueConnectionQuery.data} />
      {deviceQuery.isSuccess && <DevicesClickCharts items={deviceQuery.data} />}
      {connectionsQuery.isSuccess && (
        <LastDaysConnectionsChart items={connectionsQuery.data} />
      )}
    </Stack>
  );
};

export default TrackingUserConnection;
