import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@material-ui/lab";
import PropTypes from "prop-types";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import {
  Alert,
  Box,
  Button,
  CardContent,
  Divider,
  FormControl,
  Grid,
  FormControlLabel,
  Stack,
  Checkbox,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { patchUser } from "../../http/user";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/componentHelpers/trackings";

const RemindersReportingForm = ({ user }) => {
  const history = useHistory();

  const schema = yup.object().shape({
    daily: yup.boolean(),
    weekly: yup.boolean(),
    monthly: yup.boolean(),
  });

  const { handleSubmit, control } = useForm({
    defaultValues: {
      daily: user?.reminderFrequency.includes("DAILY") || false,
      weekly: user?.reminderFrequency.includes("WEEKLY") || false,
      monthly: user?.reminderFrequency.includes("MONTHLY") || false,
    },
    resolver: yupResolver(schema),
  });

  const track = useTrackingContext()

  const goBack = () => {
    history.push("/my/reminders");
  };

  const onSubmit = async (data) => {
    track.click(ACTION_CLICK.UPDATE_REPORTING)

    const reminderFrequency = [];
    if (data.daily) {
      reminderFrequency.push("DAILY");
    }
    if (data.weekly) {
      reminderFrequency.push("WEEKLY");
    }
    if (data.monthly) {
      reminderFrequency.push("MONTHLY");
    }
    mutation.mutate({
      reminderFrequency: reminderFrequency,
      user,
    });
  };

  const mutation = useMutation(patchUser);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <CardContent sx={{ p: 3 }}>
        <Box>
          {mutation.isError ? <Alert severity="error">Une erreur est survenue : {mutation.error.message}</Alert> : null}

          {mutation.isSuccess ? <Alert severity="success">Modification réussie.</Alert> : null}

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="filled">
                <Controller
                  name="daily"
                  control={control}
                  shouldUnregister
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox onChange={(e) => field.onChange(e.target.checked)} checked={field.value} />}
                      label="Quotidien"
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth variant="filled">
                <Controller
                  name="weekly"
                  control={control}
                  shouldUnregister
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox onChange={(e) => field.onChange(e.target.checked)} checked={field.value} />}
                      label="Hebdomadaire"
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth variant="filled">
                <Controller
                  name="monthly"
                  control={control}
                  shouldUnregister
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox onChange={(e) => field.onChange(e.target.checked)} checked={field.value} />}
                      label="Mensuel"
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
      <Divider />
      <Stack
        direction={{ xs: "column-reverse", sm: "row" }}
        spacing={2}
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button variant="outlined" onClick={goBack}>
          Retour
        </Button>
        <LoadingButton loading={mutation.isLoading} type="submit" variant="contained">
          Valider
        </LoadingButton>
      </Stack>
    </form>
  );
};

RemindersReportingForm.propTypes = {
  user: PropTypes.object,
};

export default RemindersReportingForm;
