import React, { useContext } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Refresh as RefreshIcon, Search as SearchIcon } from "@material-ui/icons";
import { MobileDateRangePicker } from "@material-ui/lab";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getFilenameWithoutExtension } from "../../utils/formatters";
import { ReactReduxContext } from "react-redux";
import AutocompleteDocumentField from "./AutocompleteDocumentsField";
import ControlledSelectInputField from "../form/ControlledSelectInputField";
import { requestModificationStatus } from "../../utils/ressources/documents";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/componentHelpers/trackings";

const schema = yup.object().shape({
  createdAt: yup.array().of(yup.date().nullable()), // date interval
  isUserRequests: yup.boolean(), // id of user
  document: yup.object(), // id of document
  status: yup.string()
});

const ModificationRequestToolbar = ({ onFilter, fromAgent }) => {

  const statusList = Object.values(requestModificationStatus);

  const track = useTrackingContext()

  // get connected user consultantId
  const { store } = useContext(ReactReduxContext);
  const consultantId = store.getState().auth.consultantId;

  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      createdAt: [null, null],
      isUserRequests: false,
      document: {},
      status: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {

    let filterData = {
      userId: null, 
      document:"",
      createdAt: [],
      status: "",
    }

    // if the field user is not empty
    if (data.isUserRequests) {
      filterData = {...filterData, userId: consultantId};
    }

    // if a document is selected
    if (data.document) {
      filterData = {...filterData, document: getFilenameWithoutExtension(data.document.displayName)};
    }

    // if a date interval is chosen
    if (data.createdAt[0] || data.createdAt[1]) {
      filterData = {...filterData, createdAt: data.createdAt};
    }

    // if state is selected
    if (data.status) {
      filterData = {...filterData, status: data.status};
    }

    track.click(ACTION_CLICK.SET_FILTER)
    onFilter(filterData);
  };

  const handleReset = () => {
    setValue('createdAt', new Date());
    reset();

    track.click(ACTION_CLICK.RESET_FILTER)
    onFilter({})
  }

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Controller
              name="createdAt"
              control={control}
              onChange={(event, newValue) => setValue("createdAt", newValue)}
              render={({ field }) => (
                <MobileDateRangePicker
                  {...field}
                  startText="Date de publication du"
                  endText="au"
                  inputFormat="DD/MM/YYYY"
                  allowSameDateSelection
                  okText="Valider"
                  cancelText="Annuler"
                  toolbarTitle="Choisissez une plage de date"
                  renderInput={(startProps, endProps) => (
                    <>
                      <TextField
                        {...startProps}
                        fullWidth
                        variant="filled"
                        InputLabelProps={{ shrink: true }}
                        helperText=""
                      />
                      <Typography sx={{ mx: 2 }} variant="body1">
                        {" "}
                        -{" "}
                      </Typography>
                      <TextField
                        {...endProps}
                        fullWidth
                        variant="filled"
                        InputLabelProps={{ shrink: true }}
                        helperText=""
                      />
                    </>
                  )}
                />
              )}
            />
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControlLabel
            control={
              <Controller
                name="isUserRequests"
                control={control}
                render={({ field: props }) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label="Mes demandes"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="document"
            control={control}
            defaultValue={{}}
            render={({ field, formState: { errors } }) => (
              <>
                <AutocompleteDocumentField
                  field={field}
                  errors={errors}
                />
              </>
            )}
          />
        </Grid>

        <Grid item xs={2}>
          <ControlledSelectInputField
            control={control} 
            name="status" 
            title="Status"
            list={statusList}
          />
        </Grid>

        {!fromAgent && <Grid item display={{ xs: "none", lg: "block" }} lg={9} />}
        <Grid item xs={6} lg={3} container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Button fullWidth variant="contained" startIcon={<SearchIcon />} type="submit">
              Filtrer
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth variant="outlined" startIcon={<RefreshIcon />} onClick={handleReset}>
              Réinitialiser
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ModificationRequestToolbar;
