import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker, LoadingButton } from "@material-ui/lab";
import React, { useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import palette from "../../theme/palette";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import AutocompleteProfilesField from "../form/AutocompleteProfilesField";
import { dateToStringYmd } from "../../utils/formatters";
import { patchNotification, postNotification } from "../../http/notifications";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/componentHelpers/trackings";

const schema = yup.object().shape({
  title: yup.string().required("Ce champ est obligatoire."),
  description: yup.string().required("Ce champ est obligatoire"),
  profilesToNotify: yup.array().required("Ce champ est obligatoire"),
  createdAt: yup.string(""),
  expireAt: yup.string(""),
  urlLink: yup.string("").url("Ce champ doit être une URL valide"),
});

/** Add, Edit and ReadOnly Form for ModificationRequest  */
const NotificationForm = ({ toggleFormModal, clickedNotification }) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  // request Data
  const {
    id: requestId,
    title: requestTitle,
    description: requestDescription,
    profilesToNotify: requestProfiles,
    expireAt: requestExpireAt,
    createdAt: requestCreatedAt,
    urlLink: requestUrlLink,
  } = clickedNotification;

  // if the form is opened following click on request Title in the list
  const isEditForm = clickedNotification.id ? true : false;

  const queryClient = useQueryClient();
  const track = useTrackingContext()

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
      profilesToNotify: [],
      expireAt: "",
      createdAt: "",
      urlLink: "",
    },
    resolver: yupResolver(schema),
  });

  // if it is an edit form, then set form values.
  useEffect(() => {
    if (requestId) {
      setValue("title", requestTitle);
      setValue("description", requestDescription);
      setValue("profilesToNotify", requestProfiles);
      setValue("expireAt", requestExpireAt);
      setValue("createdAt", requestCreatedAt);
      setValue("urlLink", requestUrlLink);
    }
  }, [requestId, setValue]);

  const mutationAddNotification = useMutation(postNotification, {
    onSuccess: () => {
      toggleFormModal();
      queryClient.invalidateQueries({
        queryKey: ["getNotificationAll"],
      });
      enqueueSnackbar("la notification est créé", { variant: "success" });
    },
  });

  const mutationEditNotification = useMutation(patchNotification, {
    onSuccess: () => {
      toggleFormModal();
      queryClient.invalidateQueries({ queryKey: ["getNotificationAll"] });
      enqueueSnackbar("la notification est mise à jour", {
        variant: "success",
      });
    },
  });

  // On Submit we mix data from the submit, text editor and the added image
  const onSubmit = async (data, event, requestId) => {
    track.click(ACTION_CLICK.NOTY_EDIT)

    // Edit Form
    if (isEditForm) {
      const editData = {
        ...data,
        expireAt: dateToStringYmd(data.expireAt),
      };

      // exclude les 2 champs suivant du Patch.
      delete editData.profilesToNotify;
      delete editData.createdAt;

      mutationEditNotification.mutate({ editData, requestId });
    } else {
      // Add Form
      // list of Uris of chosen profiles
      const profileList = data.profilesToNotify.map(
        (profile) => profile["@id"],
      );

      const postData = {
        ...data,
        profilesToNotify: profileList,
        expireAt: dateToStringYmd(data.expireAt),
        createdAt: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      };

      mutationAddNotification.mutate(postData);
    }
  };

  return (
    <Container maxWidth={false}>
      <Container maxWidth="lg">
        <Paper sx={{ pb: 2, overflow: "hidden" }}>
          <>
            <form
              id="notification-form"
              key="notification-form"
              noValidate
              onSubmit={handleSubmit((data, event) =>
                onSubmit(data, event, requestId),
              )}
            >
              {mutationAddNotification.isError ? (
                <Alert severity="error">
                  Une erreur est survenue :{" "}
                  {mutationAddNotification.error.message}
                </Alert>
              ) : null}
              <Box sx={{ pt: 3 }}>
                <Container maxWidth="md">
                  {isEditForm && (
                    <Grid container spacing={4} alignItems="center">
                      <Grid item xs={12} sm={4}>
                        <Controller
                          name="createdAt"
                          control={control}
                          shouldUnregister
                          render={({ field, formState: { errors } }) => (
                            <DatePicker
                              {...field}
                              label="Date de la demande"
                              inputFormat="DD/MM/YYYY"
                              disabled={isEditForm}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  margin="dense"
                                  fullWidth
                                  error={Boolean(errors.createdAt?.message)}
                                  helperText={errors.createdAt?.message}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="title"
                        control={control}
                        variant="outlined"
                        shouldUnregister
                        render={({ field, formState: { errors } }) => (
                          <TextField
                            {...field}
                            error={Boolean(errors.title?.message)}
                            helperText={errors.title?.message}
                            className={classes.titleField}
                            label="Titre"
                            margin="normal"
                            autoComplete="title"
                            fullWidth
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid item my={2}>
                    <Controller
                      name="description"
                      control={control}
                      defaultValue=""
                      render={({ field, formState: { errors } }) => (
                        <TextField
                          {...field}
                          error={Boolean(errors.description?.message)}
                          helperText={errors.description?.message}
                          className={classes.titleField}
                          label="Description"
                          autoComplete="description"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>

                  <Grid item my={2}>
                    <Controller
                      name="urlLink"
                      control={control}
                      defaultValue=""
                      render={({ field, formState: { errors } }) => (
                        <TextField
                          {...field}
                          error={Boolean(errors.urlLink?.message)}
                          helperText={errors.urlLink?.message}
                          className={classes.titleField}
                          label="Lien"
                          autoComplete="Lien URL"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} mb={2}>
                    <Controller
                      name="expireAt"
                      control={control}
                      shouldUnregister
                      render={({ field, formState: { errors } }) => (
                        <DatePicker
                          {...field}
                          label="Date d'expiration de la demande"
                          inputFormat="DD/MM/YYYY"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              margin="dense"
                              fullWidth
                              error={Boolean(errors.createdAt?.message)}
                              helperText={errors.createdAt?.message}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Controller
                      name="profilesToNotify"
                      control={control}
                      defaultValue={[]}
                      render={({ field, formState: { errors } }) => (
                        <AutocompleteProfilesField
                          field={field}
                          errors={errors}
                          disabled={isEditForm}
                        />
                      )}
                    />
                  </Grid>
                  <Box className={classes.actionWrapper}>
                    <Button variant="outlined" onClick={toggleFormModal}>
                      Annuler
                    </Button>
                    <LoadingButton
                      loading={mutationAddNotification.isLoading}
                      form="notification-form"
                      type="submit"
                      variant="contained"
                    >
                      Envoyer
                    </LoadingButton>
                  </Box>
                </Container>
              </Box>
            </form>
          </>
        </Paper>
      </Container>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  pictureIcon: {
    position: "absolute",
    bottom: "1rem",
    right: "1rem",
    backgroundColor: "#FFFFFF",
    color: palette.hubnup.main,
  },
  noImage: {
    minHeight: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  noImageError: {
    color: "red",
  },
  imageContainer: {
    position: "relative",
  },
  imageNews: {
    width: "100%",
    height: "auto",
    maxHeight: "400px",
    objectFit: "cover",
  },
  titleField: {
    "& input": {
      fontSize: "20px",
    },
    "& label": {
      fontSize: "25px",
    },
  },
  actionWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "1rem",

    "& button:not(:first-child)": {
      marginLeft: "1rem",
    },
  },
}));

export default NotificationForm;
