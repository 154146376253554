import { LoadingButton } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { deleteReminders } from "../../http/reminders";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/componentHelpers/trackings";

const RemindersDeleteDialog = ({ isOpen, hasMany, onClose, remindersToDelete, goBack }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const queryClient = useQueryClient();
  const track = useTrackingContext()

  const onConfirm = () => {
    track.click(ACTION_CLICK.DELETE_REMINDER)
    mutation.mutate(remindersToDelete);
  };

  const mutation = useMutation(deleteReminders, {
    onSuccess: () => {
      if (goBack) {
        history.push(goBack);
      }

      queryClient.invalidateQueries("getRemindersAll");
      enqueueSnackbar(hasMany ? "Les rappels ont bien été supprimés." : "Le rappel a bien été supprimé.", {
        variant: "success",
      });
      onClose();
    },
  });

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="delete-reminder-dialog-title"
      aria-describedby="delete-reminder-dialog-description"
    >
      <DialogTitle id="delete-reminder-dialog-title" disableTypography>
        <Typography variant="h4" component="div">
          Suppression
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="delete-reminder-dialog-description">
          Confirmez-vous la suppression {!hasMany ? "du rappel" : "des rappels"} ?
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" onClick={onClose} autoFocus>
          Annuler
        </Button>
        <LoadingButton
          loading={mutation.isLoading}
          variant="contained"
          onClick={onConfirm}
          startIcon={<DeleteIcon />}
          color="hubnup"
        >
          Supprimer
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

RemindersDeleteDialog.propTypes = {
  isOpen: PropTypes.bool,
  hasMany: PropTypes.bool,
  onClose: PropTypes.func,
  remindersToDelete: PropTypes.array,
  goBack: PropTypes.string,
};

export default RemindersDeleteDialog;
