import React, { useState } from "react";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Box, Container, Tab } from "@material-ui/core";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { getUser } from "../../http/user";
import TrackingUserConnection from "../../components/trackings/details/TrackingUserConnection";
import AppCircularProgress from "../../components/AppCircularProgress";
import TrackingUserPages from "../../components/trackings/details/TrackingUserPages";
import TrackingUserDocuments from "../../components/trackings/details/TrackingUserDocuments";
import Breadcrumbs from "../../components/Breadcrumbs";

const TABS = {
  CONNECTIONS: { label: "Connexions", component: TrackingUserConnection },
  PAGES: { label: "Détails par page", component: TrackingUserPages },
  DOCUMENTS: { label: "Documents", component: TrackingUserDocuments },
};

const DetailsTrackingStatistics = () => {
  const [activeTab, setActiveTab] = useState("CONNECTIONS");

  const params = useParams();

  const userQuery = useQuery({
    queryKey: ["tracking", params.id],
    queryFn: () => getUser(params.id),
  });

  return (
    <>
      <Container maxWidth={false}>
        <Breadcrumbs />
      </Container>
      <TabContext value={activeTab} sx={{ p: 0 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={(event, tab) => setActiveTab(tab)}
            aria-label="lab API tabs example"
          >
            {Object.entries(TABS).map(([key, tab]) => (
              <Tab key={key} label={tab.label} value={key} />
            ))}
          </TabList>
        </Box>
        {userQuery.isSuccess ? (
          Object.entries(TABS).map(([key, tab]) => (
            <TabPanel key={key} label={tab.label} value={key}>
              <tab.component user={userQuery.data} />
            </TabPanel>
          ))
        ) : (
          <AppCircularProgress />
        )}
      </TabContext>
    </>
  );
};

export default DetailsTrackingStatistics;
