import { DataGrid } from "@material-ui/data-grid";
import PropTypes from "prop-types";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { Alert, Box, Card, CardContent, CardHeader, Divider, Checkbox } from "@material-ui/core";
import { getAgentComponents, putComponent } from "../../http/components";
import renderCellExpand from "../../utils/renderCellExpand";
import AddProfile from "./AddProfile";
import RemoveProfile from "./RemoveProfile";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/componentHelpers/trackings";

const HubnupComponent = ({ profiles, children }) => {
  const profilesFiltered = profiles.filter((item) => item.type === "Hubnup");

  const track = useTrackingContext()

  const { isLoading, data } = useQuery("getHubnupComponents", async () => {
    const data = await getAgentComponents();

    return data.filter((item) => item.type === "Hubnup");
  });

  const mutation = useMutation(putComponent);

  const handleChange = (row, profilId) => {
    const foundAt = row.profiles.findIndex((profile) => profile === profilId);
    if (foundAt !== -1) {
      row.profiles.splice(foundAt, 1);
    } else {
      row.profiles.push(profilId);
    }
    track.click(ACTION_CLICK.UPDATE_PROFIL)
    mutation.mutate(row);
  };

  const columns = [
    { field: "priority", headerName: "Ordre", sortable: false, hide: true },
    { field: "page", headerName: "Page", sortable: false, flex: 1, renderCell: renderCellExpand },
    { field: "title", headerName: "Composant", sortable: false, flex: 1, renderCell: renderCellExpand },
  ];

  profilesFiltered.forEach((profil) => {
    columns.push({
      field: `actions-${profil.title}`,
      headerName: profil.title,
      headerAlign: "center",
      sortable: false,
      flex: 0.3,
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-around" }}>
          <Checkbox
            checked={params.row.profiles.includes(profil["@id"])}
            onChange={() => handleChange(params.row, profil["@id"])}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </Box>
      ),
    });
  });

  const sortModel = [{ field: "priority", sort: "asc" }];

  return (
    <Card {...children}>
      <CardHeader title="Espace Hubn'UP - Gestion des profils"/>
      <Divider/>
      <CardContent>
        <Box mb={2}>
          <AddProfile type="Hubnup"/>
          <RemoveProfile profiles={profilesFiltered}/>
        </Box>
        <Box>
          {mutation.isError ? <Alert severity="error">Une erreur est survenue : {mutation.error.message}</Alert> : null}

          <DataGrid
            loading={isLoading}
            rows={data ? data : []}
            columns={columns}
            sortModel={sortModel}
            pageSize={10}
            rowHeight={35}
            autoHeight
            disableColumnFilter
            disableSelectionOnClick
            disableColumnMenu
            disableColumnReorder
          />
        </Box>
      </CardContent>
    </Card>
  );
};

HubnupComponent.propTypes = {
  profiles: PropTypes.array,
};

export default HubnupComponent;
