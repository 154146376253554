import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import { Refresh as RefreshIcon, Search as SearchIcon } from "@material-ui/icons";
import { Controller, useForm } from "react-hook-form";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/componentHelpers/trackings";

const DocumentsToolbar = ({ onFilter, filters }) => {
  const defaultValues = {
    title: "",
  };

  const track = useTrackingContext()

  const { control, handleSubmit, reset } = useForm({
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (!filters.title) {
      reset();
    }
  }, [filters, reset]);

  const onSubmit = async (data) => {
    onFilter({
      ...data,
    });

    track.click(ACTION_CLICK.SET_FILTER)
  };

  const onReset = () => {
    reset();
    onFilter(defaultValues);

    track.click(ACTION_CLICK.RESET_FILTER)
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={6} lg={4}>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Nom du fichier"
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} lg={4} container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Button fullWidth variant="contained" startIcon={<SearchIcon />} type="submit">
              Rechercher
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth variant="outlined" startIcon={<RefreshIcon />} onClick={() => onReset()}>
              Réinitialiser
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

DocumentsToolbar.propTypes = {
  onFilter: PropTypes.func,
  filters: PropTypes.object,
};

export default DocumentsToolbar;
