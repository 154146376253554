import React from "react";
import dayjs from "dayjs";
import { Box, Paper } from "@material-ui/core";
import { Chart } from "react-google-charts";
import { LoadingButton } from "@material-ui/lab";
import { Download } from "@material-ui/icons";
import useAction from "../../../hooks/useAction";

const LastDaysConnectionsChart = ({ items, onExport }) => {
  const options = {
    title: "Nombre de connexions journalières (sur les 10 derniers jours)",
    chartArea: { width: "60%" },
    isStacked: true,
    colors: ["#059669"],
    hAxis: {
      title: "Nombre de connexion",
      minValue: 0,
    },
    vAxis: {
      title: "Jour",
    },
  };

  const action = useAction();

  return (
    <Paper elevation={2} sx={{ position: "relative" }}>
      <Chart
        chartType="BarChart"
        width="100%"
        height="600px"
        options={options}
        data={[
          ["Jour", "Connexion"],
          ...items.map((item) => [
            dayjs(item.date).format("DD/MM/YYYY"),
            item.value,
          ]),
        ]}
      />
      {onExport && (
        <Box sx={{ position: "absolute", top: "2%", right: "2%" }}>
          <LoadingButton
            loading={action.pending}
            startIcon={<Download/>}
            variant="contained"
            onClick={action.handle(() => onExport())}
          >
            Exporter
          </LoadingButton>
        </Box>
      )}
    </Paper>
  );
};

export default LastDaysConnectionsChart;
