import { LoadingButton } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { Card, CardHeader, Container, Divider, Grid, Stack, Typography } from "@material-ui/core";
import { Download as DownloadIcon } from "@material-ui/icons";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import CustomerInfo from "../../components/customer/CustomerInfo";
import CustomersDetailsInvestmentList from "../../components/customer/CustomersDetailsInvestmentList";
import CustomersDetailsRealEstateList from "../../components/customer/CustomersDetailsRealEstateList";
import GuardedComponent from "../../components/GuardedComponent";
import SelectYearsInput from "../../components/SelectYearsInput";
import { getCustomerDetails, getExportCustomerDetails } from "../../http/clients";
import isAgent from "../../utils/isAgent";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/componentHelpers/trackings";

const CustomersDetail = () => {
  const fromAgent = isAgent();
  const [years, setYears] = useState([]);
  const { id } = useParams();
  const {
    isFetched,
    isFetching,
    data: customer,
  } = useQuery(["getCustomerDetails", id, years], () => getCustomerDetails(id, years));

  const track = useTrackingContext()

  const handleExportExcel = () => {
    track.click(ACTION_CLICK.EXPORT_MY_SALES_IMMO_CLIENT)
    mutation.mutate({ id, years, customer });
  };

  const mutation = useMutation(getExportCustomerDetails);

  useEffect(() => track.click(ACTION_CLICK.SET_FILTER), [years])

  return (
    <Container maxWidth={false}>
      <Breadcrumbs
        append={
          <Typography color="textPrimary">
            {customer?.clientLastName} {customer?.clientFirstName}
          </Typography>
        }
      />

      <Grid container spacing={2}>
        <GuardedComponent componentName="customers_detail_export">
          <Grid item>
            <Stack direction="row" spacing={2}>
              <LoadingButton
                loading={mutation.isLoading}
                color="primary"
                variant="contained"
                startIcon={<DownloadIcon/>}
                onClick={handleExportExcel}
              >
                Exporter
              </LoadingButton>
            </Stack>
          </Grid>
        </GuardedComponent>

        <GuardedComponent componentName="customers_detail_infos">
          <Grid item xs={12}>
            <CustomerInfo customer={customer} loading={!isFetched || isFetching} fromAgent={fromAgent}/>
          </Grid>
        </GuardedComponent>

        <GuardedComponent componentName="customers_detail_real_instate">
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={<Typography variant="h2">Suivi immobilier</Typography>}
                action={<SelectYearsInput value={years} setValue={setYears}/>}
              />
              <Divider/>
              <CustomersDetailsRealEstateList
                customerId={id}
                sales={customer?.realEstate}
                isFetching={!isFetched || isFetching}
                fromAgent={fromAgent}
                customer={customer}
              />
            </Card>
          </Grid>
        </GuardedComponent>

        <GuardedComponent componentName="customers_detail_investment">
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={<Typography variant="h2">Suivi placement</Typography>}
                action={<SelectYearsInput value={years} setValue={setYears}/>}
              />
              <Divider/>
              <CustomersDetailsInvestmentList
                customerId={id}
                sales={customer?.investment}
                isFetching={!isFetched || isFetching}
                fromAgent={fromAgent}
              />
            </Card>
          </Grid>
        </GuardedComponent>
      </Grid>
    </Container>
  );
};

export default CustomersDetail;
