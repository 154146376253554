import React, { useState } from "react";
import { Box, Container, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";

import Top50 from "../../components/trackings/globals/Top50";
import TrackingConnections from "../../components/trackings/globals/TrackingConnections";
import TrackingPages from "../../components/trackings/globals/TrackingPages";
import TrackingDocuments from "../../components/trackings/globals/TrackingDocuments";
import Breadcrumbs from "../../components/Breadcrumbs";

const TABS = {
  TOP_50: { label: "Top 50", component: Top50 },
  CONNECTION: { label: "Connexions", component: TrackingConnections },
  DETAILS_PAGE: {
    label: "Détails par page",
    component: TrackingPages,
  },
  DOCUMENT: { label: "Documents", component: TrackingDocuments },
};

const GlobalTrackingStatistics = () => {
  const [activeTab, setActiveTab] = useState("TOP_50");

  return (
    <>
      <Container maxWidth={false}>
        <Breadcrumbs />
      </Container>
      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={(event, tab) => setActiveTab(tab)}
            aria-label="lab API tabs example"
          >
            {Object.entries(TABS).map(([key, tab]) => (
              <Tab key={key} label={tab.label} value={key} />
            ))}
          </TabList>
        </Box>
        {Object.entries(TABS).map(([key, tab]) => (
          <TabPanel key={key} value={key}>
            <tab.component />
          </TabPanel>
        ))}
      </TabContext>
    </>
  );
};

export default GlobalTrackingStatistics;
