import { yupResolver } from "@hookform/resolvers/yup";
import { Create as CreateIcon } from "@material-ui/icons";
import { DatePicker, LoadingButton } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import * as yup from "yup";
import AppCircularProgress from "../../components/AppCircularProgress";
import Breadcrumbs from "../../components/Breadcrumbs";
import NewsAction from "../../components/news/NewsActions";
import NewsAddPictureModal from "../../components/news/NewsAddPictureModal";
import { getNews, getNewsAll, postNews } from "../../http/news";
import palette from "../../theme/palette";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { editorToolbar } from "../../utils/editorToolbarConfig";
import isAgent from "../../utils/isAgent";
import AutocompleteProfilesField from "../../components/form/AutocompleteProfilesField";
import dayjs from "dayjs";
import { postNotification } from "../../http/notifications";
import useNotificationRule from "../../hooks/useNotificationRule";
import { ACTION_CLICK } from "../../utils/componentHelpers/trackings";
import { useTrackingContext } from "../../contexts/TrackingContext";

const schema = yup.object().shape({
  title: yup.string().min(4).required("Ce champ est obligatoire."),
  date: yup.date().nullable().required("Ce champ est obligatoire."),
  displayed: yup.boolean(),
  hasPopup: yup.boolean(),
  notification: yup.boolean(),
  sliderDisplayed: yup.boolean(),
});

const NewsEdit = () => {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();

  const fromAgent = isAgent();

  const { isFetched, data: news } = useQuery(
    ["getNews", id],
    () => getNews(id),
    { enabled: !!id },
  );

  const [openPictureModal, setOpenPictureModal] = useState(false);
  const [picture, setPicture] = useState(null);
  const [pictureError, setPictureError] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [content, setContent] = useState("");
  const [isMaxSlider, setIsMaxSlider] = useState(false);
  const track = useTrackingContext()

  const canAddMoreSliderNews = (data) => {
    if (data.length < 3 || data.length > 3) {
      return false;
    }
    return data.length === 3 && !data.find((news) => news.id === id);
  };

  useQuery(
    ["getNewsWithSlider"],
    async () => {
      const data = await getNewsAll(fromAgent, 1, { sliderDisplayed: true });

      return data["hydra:member"];
    },
    {
      onSuccess: (data) => {
        const isSliderMax = canAddMoreSliderNews(data);
        if (isSliderMax) {
          setIsMaxSlider(isSliderMax);
        }
      },
    },
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      title: "",
      date: null,
      displayed: false,
      hasPopup: false,
      notification: true,
      sliderDisplayed: false,
      profilesToNotify: [],
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (news) {
      setValue("title", news.title);
      setValue("date", news.date);
      setValue("displayed", news.displayed);
      setValue("hasPopup", news.hasPopup);
      setValue("sliderDisplayed", news.sliderDisplayed);
      setContent(news.content);
      const html = news.content;
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
    }
  }, [news, setValue]);

  const handleSetContent = (contentState) => {
    setEditorState(contentState);
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setContent(html);
  };

  const handleClickOpenPictureModal = () => setOpenPictureModal(true);
  const handleClosePictureModal = () => setOpenPictureModal(false);

  const handleAddPicture = (picture) => {
    setPicture(picture);
    setPictureError(false);
    setOpenPictureModal(false);
  };

  const onSubmit = async (data) => {
    if (!picture && !news) {
      setPictureError(true);
    } else {
      track.click(ACTION_CLICK.CREATE_NEWS)

      const response = await mutation.mutateAsync({
        ...data,
        imageBank: picture ? picture["@id"] : news?.imageBank["@id"],
        news,
        content: content,
      });

      // Crée la notification
      if (data.notification) {
        const notificationPayload = {
          title: "Nouvelle actualité !",
          description: `Une nouvelle actualité à été publié "${data.title}"`,
          profilesToNotify: data.profilesToNotify.map(
            (profile) => profile["@id"],
          ),
          expireAt: dayjs(data.date).add(7, "days").format("YYYY-MM-DD"),
          createdAt:
            dayjs(data.date).format("YYYY-MM-DD") !==
            dayjs().format("YYYY-MM-DD")
              ? `${dayjs(data.date).format("YYYY-MM-DD")} 00:00:00`
              : `${dayjs(data.date).format("YYYY-MM-DD")} ${dayjs().format("HH:mm:ss")}`,
          urlLink: `${new URL(document.URL).origin}/news/${response.data.id}`,
        };

        await notyMutation.mutateAsync(notificationPayload);
      }
    }
  };

  const goBack = () => {
    if (history.location.state) {
      history.go(history.location.state.goBack);
    }
    history.goBack();
  };

  const mutation = useMutation(postNews, {
    onSuccess: () => {
      goBack();
    },
  });

  const notyMutation = useMutation(postNotification);

  const notyActivated = useNotificationRule("activate_notifications");
  const notyNewsActivated = useNotificationRule("notify_news");

  useEffect(() => {
    setValue("notification", notyNewsActivated && notyActivated);
  }, [notyNewsActivated, notyActivated]);

  return (
    <Container maxWidth={false}>
      <Breadcrumbs
        append={
          <Typography color="textPrimary">
            {id ? news?.title : "Créer une actualité"}
          </Typography>
        }
      />

      <Container maxWidth="lg">
        <Paper sx={{ pb: 2, overflow: "hidden" }}>
          {(id && isFetched) || !id ? (
            <>
              <form
                id="edit-form"
                key="edit-form"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                {mutation.isError ? (
                  <Alert severity="error">
                    Une erreur est survenue : {mutation.error.message}
                  </Alert>
                ) : null}

                <Box className={classes.imageContainer}>
                  {picture ? (
                    <img
                      src={
                        process.env.REACT_APP_API_URL +
                        picture.mediaObject.contentUrl
                      }
                      alt={picture.title}
                      className={classes.imageNews}
                      style={{
                        width: "100%",
                        height: "auto",
                        maxHeight: "400px",
                        objectFit: "contain",
                      }}
                    />
                  ) : id ? (
                    <img
                      src={
                        process.env.REACT_APP_API_URL +
                        news.imageBank.mediaObject.contentUrl
                      }
                      alt={news.imageBank.title}
                      className={classes.imageNews}
                      style={{
                        width: "100%",
                        height: "auto",
                        maxHeight: "400px",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <Paper className={classes.noImage}>
                      <Typography>
                        Aucune image n'est actuellement attribuée à cette
                        actualité.
                      </Typography>
                      {((errors && Object.keys(errors).length !== 0) ||
                        pictureError) && (
                        <Typography className={classes.noImageError}>
                          Une image est requise.
                        </Typography>
                      )}
                    </Paper>
                  )}

                  {errors.hasPicture}
                  <IconButton
                    onClick={handleClickOpenPictureModal}
                    className={classes.pictureIcon}
                  >
                    <CreateIcon />
                  </IconButton>
                  <NewsAddPictureModal
                    open={openPictureModal}
                    onClose={handleClosePictureModal}
                    title="Ajouter une image"
                    onClick={handleAddPicture}
                  />
                </Box>
                <Box sx={{ pt: 3 }}>
                  <Container maxWidth="md">
                    <Grid container spacing={4} alignItems="center">
                      <Grid item xs={12} sm={7}>
                        <Controller
                          name="title"
                          control={control}
                          variant="outlined"
                          shouldUnregister
                          render={({ field, formState: { errors } }) => (
                            <TextField
                              {...field}
                              error={Boolean(errors.title?.message)}
                              helperText={errors.title?.message}
                              className={classes.titleField}
                              label="Titre"
                              margin="normal"
                              autoComplete="title"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={8} sm={3}>
                        <Controller
                          name="date"
                          control={control}
                          shouldUnregister
                          render={({ field, formState: { errors } }) => (
                            <DatePicker
                              {...field}
                              label="Date de publication"
                              inputFormat="DD/MM/YYYY"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  margin="dense"
                                  error={Boolean(errors.date?.message)}
                                  helperText={errors.date?.message}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={4} sm={2} sx={{ mt: { xs: 1, sm: 1.5 } }}>
                        <Grid
                          container
                          sx={{ "& span": { fontSize: "0.9rem" } }}
                        >
                          <Controller
                            name="displayed"
                            control={control}
                            shouldUnregister
                            render={({ field }) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={(e) =>
                                      field.onChange(e.target.checked)
                                    }
                                    checked={field.value}
                                  />
                                }
                                label="Visible"
                              />
                            )}
                          />
                          <Controller
                            name="hasPopup"
                            control={control}
                            shouldUnregister
                            render={({ field }) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={(e) =>
                                      field.onChange(e.target.checked)
                                    }
                                    checked={field.value}
                                  />
                                }
                                label="Activer une Pop-UP"
                              />
                            )}
                          />
                          <Controller
                            name="sliderDisplayed"
                            control={control}
                            shouldUnregister
                            render={({ field }) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={(e) =>
                                      field.onChange(e.target.checked)
                                    }
                                    checked={field.value}
                                    disabled={isMaxSlider}
                                  />
                                }
                                label="Afficher en Carousel"
                              />
                            )}
                          />
                          <Controller
                            name="notification"
                            control={control}
                            shouldUnregister
                            render={({ field }) => (
                              <FormControlLabel
                                disabled={!notyActivated || !notyNewsActivated}
                                control={
                                  <Checkbox
                                    onChange={(e) =>
                                      field.onChange(e.target.checked)
                                    }
                                    checked={field.value}
                                  />
                                }
                                label="Notification"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      my={2}
                      sx={{
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        minHeight: "300px",
                        borderRadius: "4px",
                        "&:hover": {
                          border: "1px solid #172b4d",
                        },
                        "&:focus-within": {
                          border: "2px solid #2a6775",
                        },
                      }}
                    >
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={handleSetContent}
                        toolbar={editorToolbar}
                      />
                    </Grid>
                    {watch("notification") && (
                      <Grid sx={{ pb: 3 }}>
                        <Controller
                          name="profilesToNotify"
                          control={control}
                          disabled={!watch("notification")}
                          defaultValue={[]}
                          render={({ field, formState: { errors } }) => (
                            <AutocompleteProfilesField
                              defaultAll
                              label="Profile de notification"
                              disabled={!watch("notification")}
                              field={field}
                              errors={errors}
                            />
                          )}
                        />
                      </Grid>
                    )}
                  </Container>
                </Box>
              </form>

              <Container maxWidth="md">
                <Box className={classes.actionWrapper}>
                  <Button variant="outlined" onClick={goBack}>
                    Annuler
                  </Button>
                  {news && (
                    <NewsAction
                      news={news}
                      goBack={history.location.pathname}
                      fromDetails={true}
                    />
                  )}
                  <LoadingButton
                    loading={mutation.isLoading}
                    type="submit"
                    variant="contained"
                    form="edit-form"
                  >
                    Enregistrer
                  </LoadingButton>
                </Box>
              </Container>
            </>
          ) : (
            <AppCircularProgress />
          )}
        </Paper>
      </Container>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  pictureIcon: {
    position: "absolute",
    bottom: "1rem",
    right: "1rem",
    backgroundColor: "#FFFFFF",
    color: palette.hubnup.main,
  },
  noImage: {
    minHeight: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  noImageError: {
    color: "red",
  },
  imageContainer: {
    position: "relative",
  },
  imageNews: {
    width: "100%",
    height: "auto",
    maxHeight: "400px",
    objectFit: "cover",
  },
  titleField: {
    "& input": {
      fontSize: "25px",
    },
    "& label": {
      fontSize: "25px",
    },
  },
  actionWrapper: {
    display: "flex",
    justifyContent: "flex-end",

    "& button:not(:first-child)": {
      marginLeft: "1rem",
    },
  },
}));

export default NewsEdit;
