import http from "../utils/http";
import openFile from "../utils/openFile";

export const registerEvents = (sessionId, events) => {
  return http.post(`/sessions/${sessionId}/events`, events);
};

export const retrieveTop50ConsutantsByClick = () => {
  return http.get("/tracking/top/consultants").then((resp) => resp.data);
};

export const exportTop50ConsutantsByClick = () => {
  return openFile(
    "/tracking/top/consultants/export",
  );
};

export const retrieveTop50PagesByClick = () => {
  return http.get("/tracking/top/pages").then((resp) => resp.data);
};

export const exportTop50PagesByClick = () => {
  return openFile("/tracking/top/pages/export");
};

export const retrieveTop50Documents = () => {
  return http.get("/tracking/top/documents").then((resp) => resp.data);
};

export const exportTop50Documents = () => {
  return openFile("/tracking/top/documents/export");
};

export const retrieveDeviceClick = () => {
  return http.get("/tracking/devices").then((resp) => resp.data);
};

export const retrieveUserDeviceClick = (userId) => {
  return http
    .get(`/tracking/users/${userId}/devices`)
    .then((resp) => resp.data);
};

export const retrieveUniqueConnectionByYear = () => {
  return http.get("/tracking/connections/total").then((resp) => resp.data);
};

export const retrieveUserUniqueConnectionByYear = (userId) => {
  return http
    .get(`/tracking/users/${userId}/connections/total`)
    .then((resp) => resp.data);
};

export const retrieveConnexionByDays = () => {
  return http.get("/tracking/connections/days").then((resp) => resp.data);
};

export const retrieveUserConnexionByDays = (userId) => {
  return http
    .get(`/tracking/users/${userId}/connections/days`)
    .then((resp) => resp.data);
};

export const retrievePageByWeek = () => {
  return http.get("/tracking/pages/week").then((resp) => resp.data);
};

export const retrievePagesByClick = () => {
  return http.get("/tracking/pages/clicks").then((resp) => resp.data);
};

export const retrieveUsersPagesByClick = (userId) => {
  return http
    .get(`/tracking/users/${userId}/pages/clicks`)
    .then((resp) => resp.data);
};

export const retrieveUsersPageByWeek = (userId) => {
  return http
    .get(`/tracking/users/${userId}/pages/week`)
    .then((resp) => resp.data);
};

export const retrieveDocuments = () => {
  return http.get("/tracking/documents").then((resp) => resp.data);
};

export const retrieveUserDocuments = (userId) => {
  return http
    .get(`/tracking/users/${userId}/documents`)
    .then((resp) => resp.data);
};

export const exportUserDocuments = (userId) => {
  return openFile(
    "/tracking/documents/export?userId=" + userId
  );
};

export const exportDocuments = () => {
  return openFile("/tracking/documents/export");
};

export const exportPages = () => {
  return openFile("/tracking/pages/export");
};

export const exportUserPages = (userId) => {
  return openFile(
    `/tracking/pages/export?userId=${userId}`,
  );
};

export const exportClick = () => {
  return openFile("/tracking/click/export");
};

export const exportUserClick = (userId) => {
  return openFile(
    `/tracking/click/export?userId=${userId}`,
  );
};

export const exportSession = () => {
  return openFile("/tracking/session/export");
};

export const exportUserSession = (userId) => {
  return openFile(
    `/tracking/session/export?userId=${userId}`,
  );
};
