import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker, LoadingButton } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React from "react";
import { Alert, Button, DialogActions, DialogContent, Divider, TextField } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import { postReminders } from "../../http/reminders";
import AppDialog from "../AppDialog";
import { useTrackingContext } from "../../contexts/TrackingContext";
import { ACTION_CLICK } from "../../utils/componentHelpers/trackings";

const RemindersDuplicateModal = ({ open, onClose, reminder }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const schema = yup.object().shape({
    date: yup.date().nullable().required("Ce champ est obligatoire."),
  });

  const track = useTrackingContext()

  const { handleSubmit, control, unregister } = useForm({
    defaultValues: {
      date: null,
    },
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    unregister("date");
    onClose();
  };

  const onSubmit = async (data) => {
    const duplicatedReminder = {
      title: reminder.title,
      date: data.date,
      displayed: false,
      content: reminder.content,
    };

    track.click(ACTION_CLICK.DUPLICATE_REMINDER)

    mutationDuplicate.mutate(duplicatedReminder);
  };

  const mutationDuplicate = useMutation(postReminders, {
    onSuccess: () => {
      queryClient.invalidateQueries("getRemindersAll");
      enqueueSnackbar("Le rappel a bien été créé.", { variant: "success" });
      handleClose();
    },
  });

  return (
    <AppDialog open={open} onClose={handleClose} title="Dupliquer un rappel">
      <form id="duplicate-form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          {mutationDuplicate.isError ? (
            <Alert severity="error">Une erreur est survenue : {mutationDuplicate.error.message}</Alert>
          ) : null}

          <Controller
            name="date"
            control={control}
            shouldUnregister
            render={({ field, formState: { errors } }) => (
              <DatePicker
                {...field}
                label="Date"
                inputFormat="DD/MM/YYYY"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    error={Boolean(errors.date?.message)}
                    helperText={errors.date?.message}
                    fullWidth
                  />
                )}
              />
            )}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Annuler
          </Button>
          <LoadingButton loading={mutationDuplicate.isLoading} type="submit" variant="contained" form="duplicate-form">
            Enregistrer
          </LoadingButton>
        </DialogActions>
      </form>
    </AppDialog>
  );
};

RemindersDuplicateModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  reminder: PropTypes.object,
};

export default RemindersDuplicateModal;
