import { useRef } from "react";

export const useHydra = (data) => {
  const total = useRef(0);

  const members = [];
  let ready = false;

  if (data && "hydra:member" in data && "hydra:totalItems" in data) {
    members.push(...data["hydra:member"]);
    total.current = data["hydra:totalItems"];
    ready = true;
  }

  return [members, { total: total.current, ready }];
};
