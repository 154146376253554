import React from "react";
import { useQuery } from "react-query";
import { Stack } from "@material-ui/core";

import {
  exportUserClick,
  exportUserPages,
  retrieveUsersPageByWeek,
  retrieveUsersPagesByClick,
} from "../../../http/tracking";
import TopPagesChart from "../charts/TopPagesChart";
import TopPagesByClick from "../charts/TopPagesByClick";

const TrackingUserPages = ({ user }) => {
  const pagesQuery = useQuery({
    queryKey: ["tracking", "pages", user.id],
    queryFn: () => retrieveUsersPageByWeek(user.id),
  });

  const pagesByClicksQuery = useQuery({
    queryKey: ["tracking", "pages-by-click", user.id],
    queryFn: () => retrieveUsersPagesByClick(user.id),
  });

  return (
    <Stack spacing="2rem">
      <TopPagesChart
        items={pagesQuery.data}
        onExport={() => exportUserPages(user.id)}
      />
      <TopPagesByClick
        items={pagesByClicksQuery.data}
        onExport={() => exportUserClick(user.id)}
      />
    </Stack>
  );
};

export default TrackingUserPages;
